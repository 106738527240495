import { DateTime } from "luxon";
import Countdown from "react-countdown";
import {
  NEW_PUZZLE_TEXT,
  PUZZLE_SUCCESS_DISTRIBUTION_TEXT,
  RESET_STATS_BUTTON_TEXT,
  SHARE_TEXT,
  SOLO_STATISTICS_TITLE,
} from "../../constants/strings";
import React from "react";
import { shareStatus } from "../../lib/Share";
import { CrosswordStats, DailyStats } from "../../types/stats";
import { Histogram } from "../stats/Histogram";
import { StatBar } from "../stats/StatBar";
import { BaseModal } from "./BaseModal";

type Props = {
  isOpen: boolean;
  dayOfWeek?: string;
  handleClose: () => void;
  gameStats: CrosswordStats;
  handleResetStats: () => void;
  handleShareToClipboard: () => void;
  isDarkMode: boolean;
  isGameWon: boolean;
  usedCheckOrReveal: boolean;
};

export const StatsModal = ({
  isOpen,
  dayOfWeek,
  handleClose,
  gameStats,
  isGameWon,
  handleResetStats,
  handleShareToClipboard,
  usedCheckOrReveal,
}: Props) => {
  const [isAreYouSureResetToggled, setIsAreYouSureResetToggled] = React.useState<boolean>(false);

  const onClose = () => {
    handleClose();
    setIsAreYouSureResetToggled(false);
  };

  return (
    <BaseModal title={""} isOpen={isOpen} handleClose={onClose}>
      <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">{SOLO_STATISTICS_TITLE}</h4>
      <StatBar stats={gameStats.soloStats} />
      {gameStats.soloStats.winDistribution.length > 0 && (
        <div>
          <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
            {PUZZLE_SUCCESS_DISTRIBUTION_TEXT}
          </h4>
          <Histogram stats={gameStats.soloStats} isGameWon={isGameWon} />
        </div>
      )}
      {isGameWon && (
        <div className="mt-5 sm:mt-6 columns-2 dark:text-white">
          <div>
            <h5>{NEW_PUZZLE_TEXT}</h5>
            <Countdown
              className="text-lg font-medium text-gray-900 dark:text-gray-100"
              date={DateTime.now().plus({ days: 1 }).toISODate()}
              daysInHours={true}
            />
          </div>
          <button
            type="button"
            className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            onClick={() => {
              shareStatus(
                DateTime.now().toISODate(),
                dayOfWeek!,
                handleShareToClipboard,
                isGameWon,
                usedCheckOrReveal,
                gameStats.soloStats.winDistribution[DateTime.now().weekday - 1] as DailyStats
              );
            }}
          >
            {SHARE_TEXT}
          </button>
        </div>
      )}
      {isAreYouSureResetToggled ? (
        <div className="flex flex-col">
          <p className="text-white">Are you sure?</p>
          <div className="flex flex-row justify-evenly">
            <button
              type="button"
              className="mt-2 rounded-md border border-transparent shadow-sm px-8 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              onClick={() => {
                handleResetStats();
                setIsAreYouSureResetToggled(false);
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className="mt-2 rounded-md border border-transparent shadow-sm px-8 py-2 bg-indigo-900 text-base font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              onClick={() => {
                setIsAreYouSureResetToggled(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      ) : (
        <button
          type="button"
          className="mt-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          onClick={() => {
            setIsAreYouSureResetToggled(true);
          }}
        >
          {RESET_STATS_BUTTON_TEXT}
        </button>
      )}
    </BaseModal>
  );
};
