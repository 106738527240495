import { useQuery } from "@tanstack/react-query";
import { Analytics } from "@vercel/analytics/react";
import confetti from "canvas-confetti";
import { DateTime } from "luxon";
import { useEffect, useMemo, useRef, useState } from "react";
import useWebSocket from "react-use-websocket";
import "./App.css";
import { AlertContainer } from "./components/alerts/AlertContainer";
import { ClueBar } from "./components/clues/ClueBar";
import { ClueSection } from "./components/clues/ClueSection";
import { Grid } from "./components/grid/Grid";
import { SkeletonGrid } from "./components/grid/LoadingGrid/SkeletonGrid";
import { Keyboard } from "./components/keyboard/Keyboard";
import { ChatModal } from "./components/modals/chat/ChatModal";
import { FeedbackModal } from "./components/modals/FeedbackModal";
import { FlagClueModal } from "./components/modals/FlagClueModal";
import { GameIncompleteModal } from "./components/modals/GameIncompleteModal";
import { HelpModal } from "./components/modals/help/HelpModal";
import { InfoModal } from "./components/modals/InfoModal";
import { InviteModal } from "./components/modals/InviteModal";
import { RoomModal } from "./components/modals/multiplayer/RoomModal";
import { SettingsModal } from "./components/modals/settings/SettingsModal";
import { StatsModal } from "./components/modals/StatsModal";
import { Navbar } from "./components/navbar/Navbar";
import {
  DISCOURAGE_INAPP_BROWSERS,
  REVEAL_TIME_MS,
  WELCOME_INFO_MODAL_MS,
  WIN_CONFETTI_DELAY_MS,
} from "./constants/settings";
import {
  CLUE_FLAG_FAILED_MESSAGE,
  CLUE_FLAG_SUCCESS_MESSAGE,
  COMPLETED_MESSAGES,
  DISCOURAGE_INAPP_BROWSER_TEXT,
  FEEDBACK_FAILED_MESSAGE,
  FEEDBACK_POSTED_MESSAGE,
  GAME_COPIED_MESSAGE,
  INCOMPLETE_PUZZLE_MESSAGE,
  STATS_RESET_MESSAGE,
  WIN_MESSAGES,
} from "./constants/strings";
import { useAlert } from "./context/AlertContext";
import { AlertFormatter } from "./lib/AlertFormatter";
import { isInAppBrowser } from "./lib/Browser";
import { parseCrossword } from "./lib/CrosswordParser";
import { CrosswordUtils } from "./lib/CrosswordUtils";
import { useElapsedTime } from "./lib/ElapsedTime";
import {
  getStoredIsHighContrastMode,
  getStoredIsTimerDisplayed,
  getStoredSwitchEnterDeleteKeys,
  loadGameStateFromLocalStorage,
  loadOrCreatePlayerFromLocalStorage,
  saveGameStateToLocalStorage,
  savePlayerToLocalStorage,
  saveTimeElapsedInLocalStorage,
  setStoredIsHighContrastMode,
  setStoredIsTimerDisplayed,
  setStoredSwitchEnterDeleteKeys,
} from "./lib/LocalStorage";
import { usePrevious, useWindowFocus } from "./lib/ReactUtils";
import { RoomManagement } from "./lib/RoomManagement";
import { addStatsForSolvingGame, addStatsForStartingGame, loadStats, resetStats } from "./lib/Stats";
import { WebsocketHandler } from "./lib/WebsocketHandler";
import { CheckType, Clue, CrosswordPuzzle, CurrentCoordinates, Direction, Square } from "./types/play";
import { ChatMessage, Color, Player, Room } from "./types/room";
import { CrosswordStats } from "./types/stats";

function App() {
  // Canvas/CSS
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Settings/Alerts
  const { showError: showErrorAlert, showSuccess: showSuccessAlert } = useAlert();
  const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") === "dark" : prefersDarkMode
  );
  const [isHighContrastMode, setIsHighContrastMode] = useState(getStoredIsHighContrastMode());
  const [isTimerDisplayed, setIsTimerDisplayed] = useState(getStoredIsTimerDisplayed());
  const [switchEnterDeleteKeys, setSwitchEnterDeleteKeys] = useState(getStoredSwitchEnterDeleteKeys());

  // Player/Room
  const [user, setUser] = useState<Player>(loadOrCreatePlayerFromLocalStorage());
  const [room, setRoom] = useState<Room | undefined>(() => {
    const loadedRoom = RoomManagement.getRoomFromUrlOrStorage(user);
    if (!loadedRoom || DateTime.fromSeconds(loadedRoom?.createdTime).toISODate() !== DateTime.now().toISODate()) {
      return undefined;
    }
    if (loadedRoom) {
      const url: URL = new URL(window.location.href);
      url.searchParams.set("roomId", loadedRoom.roomId);
      window.history.pushState({}, "", url);
      return loadedRoom;
    }
  });
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);

  // Crossword States
  const windowFocused = useWindowFocus();
  const [stats, setStats] = useState<CrosswordStats>(() => loadStats());
  const [isPuzzleLoaded, setIsPuzzleLoaded] = useState<boolean>(false);
  const [currentDirection, setCurrentDirection] = useState<Direction>(Direction.Across);
  const [isGameWon, setIsGameWon] = useState<boolean>(() => {
    const savedGameState = loadGameStateFromLocalStorage();
    if (!savedGameState) {
      return false;
    }
    return (
      DateTime.fromISO(savedGameState?.puzzle.date, { zone: "utc" }).toISODate() === DateTime.now().toISODate() &&
      savedGameState.isGameWon
    );
  });
  const [usedCheckOrReveal, setUsedCheckOrReveal] = useState<boolean>(() => {
    const savedGameState = loadGameStateFromLocalStorage();
    if (!savedGameState) {
      return false;
    }
    return (
      DateTime.fromISO(savedGameState?.puzzle.date, { zone: "utc" }).toISODate() === DateTime.now().toISODate() &&
      savedGameState.usedCheckOrReveal
    );
  });
  const [currentCoordinates, setCurrentCoordinates] = useState<CurrentCoordinates>({ x: 0, y: 0 });
  const prevCoordinates: CurrentCoordinates | undefined = usePrevious(currentCoordinates);
  const [puzzle, setPuzzle] = useState<CrosswordPuzzle | undefined>(() => {
    const savedGameState = loadGameStateFromLocalStorage();
    if (
      !savedGameState ||
      DateTime.fromISO(savedGameState?.puzzle.date, { zone: "utc" }).toISODate() !== DateTime.now().toISODate()
    ) {
      setIsPuzzleLoaded(true);
      return undefined;
    }
    if (CrosswordUtils.isPuzzleFilled(savedGameState.puzzle) && !savedGameState.isGameWon) {
      showErrorAlert(INCOMPLETE_PUZZLE_MESSAGE);
    }
    setIsPuzzleLoaded(true);
    return savedGameState.puzzle;
  });
  const elapsedTimer = useElapsedTime({ isActive: false });

  // Navbar Triggered States
  const [isAutoCheckActive, setIsAutoCheckActive] = useState<boolean>(false);
  const [isChatModalOpen, setIsChatModalOpen] = useState<boolean>(false);
  const [unseenChatNotification, setUnseenChatNotification] = useState<boolean>(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState<boolean>(false);
  const [isFlagClueModalOpen, setIsFlagClueModalOpen] = useState<boolean>(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
  const [isGameIncompleteModalOpen, setIsGameIncompleteModalOpen] = useState<boolean>(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [isRoomModalOpen, setIsRoomModalOpen] = useState<boolean>(false);
  const [isStatsModalOpen, setIsStatsModalOpen] = useState<boolean>(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<boolean>(false);

  // Websocket States
  const websocketUri: string | null = useMemo(() => {
    if (room) {
      return `${process.env.REACT_APP_WEBSOCKET_URL}/${room.roomId}`;
    }
    return null;
  }, [room]);

  const { lastMessage, sendMessage } = useWebSocket(websocketUri, {
    onOpen: () => {
      if (room && user) {
        sendMessage(WebsocketHandler.sendRoomJoinEvent(user, room));
      }
    },
    shouldReconnect: (closeEvent) => {
      console.log(closeEvent);
      return true;
    },
  });

  const isModalOpen: boolean = useMemo(
    () =>
      isChatModalOpen ||
      isFeedbackModalOpen ||
      isHelpModalOpen ||
      isGameIncompleteModalOpen ||
      isInviteModalOpen ||
      isInfoModalOpen ||
      isRoomModalOpen ||
      isStatsModalOpen ||
      isSettingsModalOpen,
    [
      isChatModalOpen,
      isFeedbackModalOpen,
      isHelpModalOpen,
      isGameIncompleteModalOpen,
      isInviteModalOpen,
      isInfoModalOpen,
      isRoomModalOpen,
      isStatsModalOpen,
      isSettingsModalOpen,
    ]
  );

  useEffect(() => {
    if (lastMessage !== null) {
      onWebsocket(lastMessage!);
    }
  }, [lastMessage]);

  const { isLoading, error, data } = useQuery(["getPuzzle", [isPuzzleLoaded]], () => {
    if (isPuzzleLoaded && puzzle) {
      return puzzle;
    }
    if (isPuzzleLoaded) {
      const newDate = DateTime.now().toISODate();
      return fetch(`${process.env.REACT_APP_SERVER_URL}/crossword/${newDate}`)
        .then((response) => response.json())
        .then((data) => parseCrossword(data))
        .then((newPuzzle) => {
          addStatsForStartingGame(stats, CrosswordUtils.getIntDayOfWeekFromString(newPuzzle.dayOfWeek));
          return newPuzzle;
        });
    }
  });

  useEffect(() => {
    if (error) {
      showErrorAlert("Error loading puzzle data! 😿 We're working on it.");
    }
  }, [error, showErrorAlert]);

  useEffect(() => {
    if (data) {
      setPuzzle(data);
      setIsPuzzleLoaded(true);
    }
  }, [data, stats]);

  useEffect(() => {
    // Check if all squares are accurate
    if (puzzle && !isGameWon && CrosswordUtils.isPuzzleFilled(puzzle)) {
      if (CrosswordUtils.isPuzzleCompleted(puzzle)) {
        const winMessage = WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)];
        const delayMs = REVEAL_TIME_MS;
        showSuccessAlert(winMessage, {
          delayMs,
          onClose: () => setIsStatsModalOpen(true),
        });
        setIsGameWon(true);
        elapsedTimer.setActive(false);
        if (!usedCheckOrReveal) {
          addStatsForSolvingGame(stats, CrosswordUtils.getIntDayOfWeekFromString(puzzle.dayOfWeek), puzzle.date);
        }
      } else {
        setIsGameIncompleteModalOpen(true);
      }
    }
  }, [isGameWon, puzzle, showSuccessAlert, stats, usedCheckOrReveal]);

  const selectedSquare: Square | undefined = useMemo(() => {
    if (puzzle) {
      return {
        ...CrosswordUtils.getSquare(puzzle, currentCoordinates.x, currentCoordinates.y),
      };
    }
  }, [currentCoordinates, puzzle]);

  // On selection change, send two events, updating the previous square and the new square
  useEffect(() => {
    if (room && puzzle && prevCoordinates && currentCoordinates) {
      const prevSquare: Square = {
        ...CrosswordUtils.getSquare(puzzle, prevCoordinates.x, prevCoordinates.y),
        activeUser: undefined,
      };
      sendMessage(WebsocketHandler.sendSquareUpdateEvent(user, room, prevSquare));
      const nextSquare: Square = {
        ...CrosswordUtils.getSquare(puzzle, currentCoordinates.x, currentCoordinates.y),
        activeUser: user,
      };
      sendMessage(WebsocketHandler.sendSquareUpdateEvent(user, room, nextSquare));
    }
  }, [currentCoordinates]);

  const currentClue: Clue | undefined = useMemo(() => {
    if (puzzle) {
      let clue = CrosswordUtils.getClue(currentDirection, puzzle, selectedSquare!);
      if (clue) {
        return clue;
      } else {
        setCurrentDirection(Direction.Down);
        return CrosswordUtils.getClue(Direction.Down, puzzle, selectedSquare!);
      }
    }
  }, [puzzle, currentDirection, selectedSquare]);

  useEffect(() => {
    if (user) {
      savePlayerToLocalStorage(user);
    }
  }, [user]);

  useEffect(() => {
    if (puzzle) {
      saveGameStateToLocalStorage({
        puzzle: puzzle,
        isGameWon: isGameWon,
        usedCheckOrReveal: usedCheckOrReveal,
      });
    }
  }, [isGameWon, puzzle, usedCheckOrReveal]);

  useEffect(() => {
    if (elapsedTimer && puzzle) {
      saveTimeElapsedInLocalStorage(elapsedTimer.elapsedTime, DateTime.fromISO(puzzle.date, { zone: "utc" }));
    }
  }, [puzzle, elapsedTimer]);

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (!loadGameStateFromLocalStorage()) {
      setTimeout(() => {
        setIsInfoModalOpen(true);
      }, WELCOME_INFO_MODAL_MS);
    }
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
        persist: false,
        durationMs: 7000,
      });
  }, [showErrorAlert]);

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    if (isHighContrastMode) {
      document.documentElement.classList.add("high-contrast");
    } else {
      document.documentElement.classList.remove("high-contrast");
    }
  }, [isDarkMode, isHighContrastMode]);

  useEffect(() => {
    if (isGameWon) {
      const delayMs = REVEAL_TIME_MS;
      const displayMessages = !usedCheckOrReveal ? WIN_MESSAGES : COMPLETED_MESSAGES;
      const message = displayMessages[Math.floor(Math.random() * displayMessages.length)];
      showSuccessAlert(message, {
        delayMs,
        onClose: () => setIsStatsModalOpen(true),
      });
      elapsedTimer.setActive(false);
      if (canvasRef.current) {
        const winConfetti = confetti.create(canvasRef.current, {
          resize: true,
          useWorker: true,
        });
        setTimeout(() => {
          winConfetti({
            particleCount: !usedCheckOrReveal ? 350 : 20, // only do a little confetti if they used check/reveal
            spread: !usedCheckOrReveal ? 360 : 45,
            startVelocity: !usedCheckOrReveal ? 45 : 10,
            origin: !usedCheckOrReveal ? { y: 0.4 } : { y: 0.1 },
          });
        }, WIN_CONFETTI_DELAY_MS);
      }
    }
  }, [isGameWon, showSuccessAlert, usedCheckOrReveal]);

  useEffect(() => {
    if (!windowFocused) {
      elapsedTimer.setActive(false);
    }
    if (windowFocused && puzzle && !isGameWon) {
      elapsedTimer.setActive(true);
    }
  }, [isGameWon, puzzle, windowFocused]);

  const handleAutoCheckToggle = (isAutoCheck: boolean) => {
    if (puzzle) {
      setUsedCheckOrReveal(true);
      setPuzzle(
        CrosswordUtils.setSquares(
          puzzle,
          puzzle.grid.flatMap((row) =>
            row.map((square) => ({
              ...square,
              isChecked: !!square.value, // only set isChecked
              isIncorrect: square.value ? square.value !== square.solution : undefined,
              noEdit: square.value === square.solution,
            }))
          )
        )
      );
      setIsAutoCheckActive(isAutoCheck);
      setIsHelpModalOpen(false);
    }
  };

  const handleCheckOrReveal = (checkType: CheckType, reveal: boolean = false) => {
    let updateSquares: Square[] = [];
    setUsedCheckOrReveal(true);
    if (puzzle && selectedSquare && checkType === CheckType.Square) {
      updateSquares = [CrosswordUtils.checkOrRevealSquare(puzzle, selectedSquare, reveal)];
    } else if (puzzle && selectedSquare && checkType === CheckType.Clue) {
      updateSquares = CrosswordUtils.getSquaresForClue(puzzle, currentClue!.clueNumber, currentClue!.direction).map(
        (square) => CrosswordUtils.checkOrRevealSquare(puzzle, square, reveal)
      );
    } else if (puzzle && selectedSquare && checkType === CheckType.Puzzle) {
      updateSquares = puzzle.grid.flatMap((row) =>
        row.map((square) => CrosswordUtils.checkOrRevealSquare(puzzle, square, reveal))
      );
    }
    const newPuzzle = CrosswordUtils.setSquares(puzzle!, updateSquares);
    if (puzzle && updateSquares.length > 0) {
      setPuzzle(newPuzzle);
      if (room) {
        const alert = `${user.userName} ${reveal ? "revealed" : "checked"} ${
          checkType === CheckType.Puzzle ? "the" : "a"
        } ${checkType.toLowerCase()}!`;
        sendMessage(WebsocketHandler.sendPuzzleUpdateEvent(user, room, newPuzzle, alert));
      }
    }
    setIsHelpModalOpen(false);
  };

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark);
    localStorage.setItem("theme", isDark ? "dark" : "light");
  };

  const handleFeedback = (feedback: string) => {
    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/feedback`, {
        method: "POST",
        mode: "no-cors",
        body: JSON.stringify({ feedback: feedback }),
      }).then((response) => response.ok);
      showSuccessAlert(FEEDBACK_POSTED_MESSAGE, {});
      setIsFeedbackModalOpen(false);
    } catch (ex) {
      showErrorAlert(FEEDBACK_FAILED_MESSAGE, {});
      console.log(ex);
    }
  };

  const handleFlagClue = () => {
    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/flagClue`, {
        method: "POST",
        mode: "no-cors",
        body: JSON.stringify({
          clue: currentClue?.clueText,
          answer: currentClue?.solution,
        }),
      }).then((response) => response.ok);
      showSuccessAlert(CLUE_FLAG_SUCCESS_MESSAGE, {});
    } catch (ex) {
      showErrorAlert(CLUE_FLAG_FAILED_MESSAGE, {});
      console.log(ex);
    }
  };

  const handleHighContrastMode = (isHighContrast: boolean) => {
    if (isHighContrastMode) {
      showErrorAlert("High contrast mode coming soon!");
    }
    setIsHighContrastMode(isHighContrast);
    setStoredIsHighContrastMode(isHighContrast);
  };

  const handleInvite = () => {
    let inviteRoom: Room;
    if (room !== undefined) {
      inviteRoom = room;
    } else {
      inviteRoom = RoomManagement.createRoom(user);
      setRoom(inviteRoom);
      sendMessage(WebsocketHandler.sendRoomJoinEvent(user, inviteRoom));
    }
    return RoomManagement.saveRoomAndCopyInviteUrlToClipboard(inviteRoom);
  };

  const handleResetStats = () => {
    const stats = resetStats();
    setIsStatsModalOpen(false);
    showSuccessAlert(STATS_RESET_MESSAGE, {});
    setStats(stats);
  };

  const handleSelectGame = (date: string) => {
    // Get puzzles 9 years ago as we have populated 2013/2014 puzzles
    const newDate = DateTime.fromISO(date).minus({ years: 0 }).toISODate();
    try {
      fetch(`${process.env.REACT_APP_SERVER_URL}/crossword/${newDate}`)
        .then((response) => response.json())
        .then((data) => parseCrossword(data))
        .then((crossword) => setPuzzle(crossword));
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleSendChatMessage = (chatMessage: string) => {
    if (room && user) {
      const newMessage = {
        sender: user,
        message: chatMessage,
        sendTime: DateTime.now().toSeconds(),
      };
      setChatMessages([...chatMessages, newMessage]);
      sendMessage(WebsocketHandler.sendChatMessageEvent(user, room, newMessage));
    }
  };

  const handleSwitchEnterDelete = (switchEnterDelete: boolean) => {
    setSwitchEnterDeleteKeys(switchEnterDelete);
    setStoredSwitchEnterDeleteKeys(switchEnterDelete);
  };

  const handleUpdateIsTimerDisplayed = (isTimerDisplayed: boolean) => {
    setIsTimerDisplayed(isTimerDisplayed);
    setStoredIsTimerDisplayed(isTimerDisplayed);
  };

  const handleUserUpdate = (userName: string, color: Color | undefined) => {
    const newUser: Player = {
      ...user,
      userName: userName,
      color: color,
    };
    if (room) {
      const alert = AlertFormatter.playerUpdateAlert(user, newUser);
      const newRoom = {
        ...room!,
        players: room!.players?.filter((player) => player.userId !== user.userId).concat([newUser]),
      };
      setRoom(newRoom);
      if (sendMessage) {
        sendMessage(WebsocketHandler.sendRoomUpdateEvent(newUser, newRoom, alert));
      }
    }
    setUser(newUser);
    setIsRoomModalOpen(false);
  };

  const onSelectCell = (xCoord: number, yCoord: number) => {
    if (xCoord === currentCoordinates.x && yCoord === currentCoordinates.y) {
      if (
        CrosswordUtils.isClueNumberDefined(
          currentDirection === Direction.Across ? Direction.Down : Direction.Across,
          puzzle!,
          selectedSquare!
        )
      ) {
        onSelectClueBar();
      }
    } else {
      if (
        CrosswordUtils.isClueNumberDefined(
          currentDirection,
          puzzle!,
          CrosswordUtils.getSquare(puzzle!, xCoord, yCoord)!
        )
      ) {
        setCurrentCoordinates({
          x: xCoord,
          y: yCoord,
        });
      } else {
        setCurrentDirection(currentDirection === Direction.Across ? Direction.Down : Direction.Across);
        setCurrentCoordinates({
          x: xCoord,
          y: yCoord,
        });
      }
    }
  };

  const onSelectClue = (clueNumber: number, direction: Direction) => {
    let [nextSquare, nextDirection] = CrosswordUtils.getSquareAndDirectionFromClue(puzzle!, clueNumber, direction);
    if (nextSquare) {
      setCurrentCoordinates({ x: nextSquare.xCoord, y: nextSquare.yCoord });
      setCurrentDirection(nextDirection);
    }
  };

  const onSelectClueBar = () => {
    const nextDirection = currentDirection === Direction.Across ? Direction.Down : Direction.Across;
    if (
      CrosswordUtils.getClue(
        nextDirection,
        puzzle!,
        CrosswordUtils.getSquare(puzzle!, currentCoordinates.x, currentCoordinates.y)
      )
    ) {
      setCurrentDirection(nextDirection);
    }
  };

  const onSelectNextClue = (moveLeft: boolean) => {
    // left = true, right = false -> right moves in positive direction
    let [nextSquare, nextDirection] = CrosswordUtils.determineNextClueAndDirection(
      currentDirection,
      puzzle!,
      selectedSquare!,
      moveLeft,
      isGameWon
    );
    if (nextSquare) {
      setCurrentCoordinates({ x: nextSquare.xCoord, y: nextSquare.yCoord });
      setCurrentDirection(nextDirection);
    }
  };

  const onChar = (value: string) => {
    if (!isModalOpen && !isGameWon && puzzle && selectedSquare) {
      if (!selectedSquare.noEdit) {
        const updateSquare = {
          ...selectedSquare,
          isIncorrect: isAutoCheckActive && value !== selectedSquare.solution,
          isChecked: isAutoCheckActive || selectedSquare.isChecked,
          noEdit: isAutoCheckActive && value === selectedSquare.solution,
          value: value,
          updateUser: user,
        };
        setPuzzle(CrosswordUtils.setSquares(puzzle, [updateSquare]));
        if (room) {
          sendMessage(WebsocketHandler.sendSquareUpdateEvent(user, room, updateSquare));
        }
      }
      if (!CrosswordUtils.isPuzzleFilled(puzzle)) {
        let [nextSquare, nextDirection] = CrosswordUtils.determineNextSquareAndDirection(
          currentDirection,
          puzzle,
          selectedSquare
        );
        setCurrentCoordinates({
          x: nextSquare.xCoord,
          y: nextSquare.yCoord,
        });
        setCurrentDirection(nextDirection);
      }
    }
  };

  const onDelete = () => {
    if (!isGameWon && puzzle && selectedSquare) {
      // Assume we clear the current square, unless it's empty - then clear the previous
      let clearSquare = selectedSquare;
      let nextDirection = currentDirection;
      if (selectedSquare.noEdit || !selectedSquare.value || selectedSquare.value.length < 1) {
        [clearSquare, nextDirection] = CrosswordUtils.determineNextSquareAndDirection(
          currentDirection,
          puzzle,
          selectedSquare,
          true,
          true
        );
      }
      if (!clearSquare.noEdit) {
        clearSquare = {
          ...clearSquare,
          isIncorrect: false,
          value: "",
        };
      }
      setPuzzle(CrosswordUtils.setSquares(puzzle, [clearSquare]));
      setCurrentCoordinates({
        x: clearSquare.xCoord,
        y: clearSquare.yCoord,
      });
      setCurrentDirection(nextDirection);
      if (room) {
        sendMessage(WebsocketHandler.sendSquareUpdateEvent(user, room, clearSquare));
      }
    }
  };

  const onNavigate = (arrowKey: string) => {
    let nextSquare = selectedSquare;
    let nextDirection = currentDirection;
    if (arrowKey === "ArrowUp" || arrowKey === "ArrowDown") {
      [nextSquare, nextDirection] = CrosswordUtils.determineNextSquareAndDirection(
        Direction.Down,
        puzzle!,
        selectedSquare!,
        arrowKey === "ArrowUp",
        true
      );
    } else if (arrowKey === "ArrowLeft" || arrowKey === "ArrowRight") {
      [nextSquare, nextDirection] = CrosswordUtils.determineNextSquareAndDirection(
        Direction.Across,
        puzzle!,
        selectedSquare!,
        arrowKey === "ArrowLeft",
        true
      );
    }
    setCurrentCoordinates({
      x: nextSquare!.xCoord,
      y: nextSquare!.yCoord,
    });
    setCurrentDirection(nextDirection);
  };

  const onEnter = () => {
    // Nothing yet
  };

  const onWebsocket = (message: MessageEvent) => {
    const {
      alert,
      chatMessage,
      puzzle: newPuzzle,
      room: newRoom,
      sendMessage: followUpMessage,
    } = WebsocketHandler.receiveMessage(user, room!, puzzle!, message);
    if (alert) {
      showErrorAlert(alert);
    }
    if (chatMessage) {
      setChatMessages([
        ...chatMessages,
        {
          sender: chatMessage.sender,
          message: chatMessage.message,
          sendTime: chatMessage.sendTime,
        },
      ]);
      if (!isChatModalOpen) {
        setUnseenChatNotification(true);
      }
    }
    if (newPuzzle) {
      setPuzzle(newPuzzle);
    }
    if (newRoom) {
      setRoom(newRoom);
    }
    if (followUpMessage) {
      sendMessage(followUpMessage);
    }
  };

  return (
    <>
      <Analytics />
      <div id="screen" className="w-screen h-screen flex flex-col select-none">
        <div id="navbar" className="z-10">
          <Navbar
            roomStatus={room}
            isChatModalOpen={isChatModalOpen}
            isTimerDisplayed={isTimerDisplayed}
            setIsChatModalOpen={setIsChatModalOpen}
            setIsFeedbackModalOpen={setIsFeedbackModalOpen}
            setIsHelpModalOpen={setIsHelpModalOpen}
            setIsInfoModalOpen={setIsInfoModalOpen}
            setIsInviteModalOpen={setIsInviteModalOpen}
            setIsRoomModalOpen={setIsRoomModalOpen}
            setIsStatsModalOpen={setIsStatsModalOpen}
            setIsSettingsModalOpen={setIsSettingsModalOpen}
            setUnseenChatNotification={setUnseenChatNotification}
            timeElapsed={elapsedTimer.elapsedTime}
            unseenChatNotification={unseenChatNotification}
          />
        </div>
        <div id="playArea" className="h-full lg:py-2 sm:px-2 lg:px-2 xl:mx-4 flex flex-col">
          <canvas
            id="confettiCanvas"
            ref={canvasRef}
            className="fixed top-0 left-0 right-0 w-full h-full z-0"
            aria-label="A canvas that is used to display confetti on puzzle completion."
          />
          <div id="playDiv" className="z-10 flex flex-col lg:flex-row lg:mb-10 grow justify-evenly">
            {isLoading ? (
              <div id="skeletonGrid" className="flex grow flex-col basis-full justify-center content-center">
                <SkeletonGrid />
              </div>
            ) : error ? null : (
              <div id="grid" className="h-full pt-1 flex flex-col justify-evenly md:basis-1/3 lg:justify-center">
                <div id="board" className="lg:pb-3">
                  <Grid
                    currentClue={currentClue}
                    direction={currentDirection}
                    isSolvedSuccessfully={isGameWon && !usedCheckOrReveal}
                    onSelect={onSelectCell}
                    room={room}
                    selectedSquare={selectedSquare!}
                    grid={puzzle ? puzzle.grid : []}
                  />
                </div>
                <div id="clueBar" className="flex justify-center lg:hidden">
                  {currentClue ? (
                    <ClueBar
                      clueNumber={currentClue ? currentClue.clueNumber : 0}
                      clueText={currentClue ? currentClue.clueText : ""}
                      onClickClueBar={onSelectClueBar}
                      onDoubleClickClueBar={() => setIsFlagClueModalOpen(true)}
                      onSelectNextClue={onSelectNextClue}
                    />
                  ) : (
                    <div />
                  )}
                </div>
                <div id="keyboard" className="md:hidden">
                  <Keyboard
                    onChar={onChar}
                    onDelete={onDelete}
                    onEnter={onEnter}
                    onNavigate={onNavigate}
                    isModalOpen={
                      isFeedbackModalOpen ||
                      isHelpModalOpen ||
                      isInfoModalOpen ||
                      isInviteModalOpen ||
                      isRoomModalOpen ||
                      isStatsModalOpen ||
                      isSettingsModalOpen
                    }
                    switchEnterDeleteKeys={switchEnterDeleteKeys}
                  />
                </div>
              </div>
            )}

            {isLoading || error ? null : (
              <div id="clues" className="hidden md:basis-1/2 md:flex md:flex-row self-center">
                <ClueSection
                  clues={puzzle ? puzzle.clues.across : []}
                  currentClue={currentClue}
                  direction={Direction.Across}
                  onSelectClue={onSelectClue}
                />
                <ClueSection
                  clues={puzzle ? puzzle.clues.down : []}
                  currentClue={currentClue}
                  direction={Direction.Down}
                  onSelectClue={onSelectClue}
                />
              </div>
            )}
            <ChatModal
              chatMessages={chatMessages}
              isOpen={isChatModalOpen}
              handleClose={() => setIsChatModalOpen(false)}
              handleSendChatMessage={handleSendChatMessage}
              room={room}
              user={user}
            />
            <FeedbackModal
              isOpen={isFeedbackModalOpen}
              handleClose={() => setIsFeedbackModalOpen(false)}
              handleSubmitFeedback={handleFeedback}
            />
            <FlagClueModal
              isOpen={isFlagClueModalOpen}
              handleClose={() => setIsFlagClueModalOpen(false)}
              handleFlagClue={handleFlagClue}
            />
            <HelpModal
              isOpen={isHelpModalOpen}
              isAutoCheckActive={isAutoCheckActive}
              handleClose={() => setIsHelpModalOpen(false)}
              handleAutoCheckToggle={handleAutoCheckToggle}
              handleCheckOrReveal={handleCheckOrReveal}
            />
            <InfoModal isOpen={isInfoModalOpen} handleClose={() => setIsInfoModalOpen(false)} roomStatus={room} />
            <GameIncompleteModal
              isOpen={isGameIncompleteModalOpen}
              handleClose={() => setIsGameIncompleteModalOpen(false)}
            />
            <InviteModal
              isOpen={isInviteModalOpen}
              handleClose={() => setIsInviteModalOpen(false)}
              handleInvite={handleInvite}
            />
            <RoomModal
              isOpen={isRoomModalOpen}
              handleClose={() => setIsRoomModalOpen(false)}
              handleInvite={handleInvite}
              handleUserUpdate={handleUserUpdate}
              roomStatus={room}
              user={user}
            />
            <StatsModal
              isOpen={isStatsModalOpen}
              dayOfWeek={puzzle?.dayOfWeek}
              handleClose={() => setIsStatsModalOpen(false)}
              gameStats={stats}
              isDarkMode={isDarkMode}
              isGameWon={isGameWon}
              handleResetStats={() => handleResetStats()}
              handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
              usedCheckOrReveal={usedCheckOrReveal}
            />
            <SettingsModal
              currentPuzzleDate={DateTime.now().toISODate()}
              handleClose={() => setIsSettingsModalOpen(false)}
              handleDarkMode={handleDarkMode}
              handleHighContrastMode={handleHighContrastMode}
              handleSelectGame={handleSelectGame}
              handleSwitchEnterDelete={handleSwitchEnterDelete}
              handleUpdateIsTimerDisplayed={handleUpdateIsTimerDisplayed}
              isDarkMode={isDarkMode}
              isHighContrast={isHighContrastMode}
              isOpen={isSettingsModalOpen}
              isSwitchEnterDelete={switchEnterDeleteKeys}
              isTimerDisplayed={isTimerDisplayed}
            />
            <AlertContainer />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
