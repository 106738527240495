import {
  ChartBarIcon,
  ChatAlt2Icon,
  CogIcon,
  SearchCircleIcon,
  UserAddIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { LightBulbIcon } from "@heroicons/react/solid";
import { Room } from "../../types/room";
import { BaseModal } from "./BaseModal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  roomStatus: Room | undefined;
};

export const InfoModal = ({ isOpen, handleClose, roomStatus }: Props) => {
  return (
    <BaseModal title="Welcome to Crossnoodle!" isOpen={isOpen} handleClose={handleClose}>
      <p className="mb-1 text-sm text-gray-500 dark:text-gray-300">
        Same old crossword puzzles, but with your friends! There are some cool features in Crossnoodle that you won't
        find anywhere else!
      </p>

      <div className="flex flex-col justify-center mb-1 mt-4 text-xs text-left">
        {roomStatus ? (
          <div>
            <div className="flex flex-row my-1">
              <UserGroupIcon className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white" />
              <p className="self-center dark:text-white">Change your username or color!</p>
            </div>
            <div className="flex flex-row my-1">
              <ChatAlt2Icon className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white" />
              <p className="self-center dark:text-white">Open a chat window to discuss the puzzle with your friends.</p>
            </div>
          </div>
        ) : (
          <div className="flex flex-row my-1">
            <UserAddIcon className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white" />
            <p className="self-center dark:text-white">Creates a link you can send to a friend to join your game!</p>
          </div>
        )}
        <div className="flex flex-row my-1">
          <SearchCircleIcon className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white" />
          <p className="self-center dark:text-white">Enable autocheck, or check/reveal part of the puzzle.</p>
        </div>
        <div className="flex flex-row my-1">
          <ChartBarIcon className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white" />
          <p className="self-center dark:text-white">Check your stats and keep your streak alive!</p>
        </div>
        <div className="flex flex-row my-1">
          <CogIcon className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white" />
          <p className="self-center dark:text-white">Enable Dark Mode or other settings.</p>
        </div>
      </div>

      <div className="mt-4 text-sm text-gray-500 dark:text-gray-300">
        It's early days right now, so expect some bugs. Use the{" "}
        <LightBulbIcon className="inline h-4 w-4 cursor-pointer dark:stroke-white" /> icon to send feedback!
      </div>
    </BaseModal>
  );
};
