import { useAlert } from "../../context/AlertContext";
import { BaseModal } from "./BaseModal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleInvite: () => URL;
};

export const InviteModal = ({ isOpen, handleClose, handleInvite }: Props) => {
  const { showSuccess: showSuccessAlert } = useAlert();

  return (
    <BaseModal title="Invite Friends!" isOpen={isOpen} handleClose={handleClose}>
      <div>
        <p className="mb-3 text-sm text-gray-500 dark:text-gray-300">
          Invite your friends to play a crossword with you. Just click "Share" and paste the link!
        </p>
      </div>

      <div
        className={"p-1 px-3 bg-sky-300 rounded-full duration-300 cursor-pointer text-white"}
        onClick={() => {
          const roomUrl = handleInvite();
          navigator.clipboard.writeText(roomUrl.toString());
          showSuccessAlert("Link copied to clipboard!");
          handleClose();
        }}
      >
        <p>Share</p>
      </div>
    </BaseModal>
  );
};
