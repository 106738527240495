import { CheckType } from "../../../types/play";
import { BaseModal } from "../BaseModal";
import { SettingsToggle } from "../settings/SettingsToggle";
import { HelpButton } from "./HelpButton";

type Props = {
  isOpen: boolean;
  isAutoCheckActive: boolean;
  handleAutoCheckToggle: (flag: boolean) => void;
  handleClose: () => void;
  handleCheckOrReveal: (checkType: CheckType, reveal: boolean) => void;
};

export const HelpModal = ({
  isOpen,
  isAutoCheckActive,
  handleAutoCheckToggle,
  handleClose,
  handleCheckOrReveal,
}: Props) => {
  return (
    <BaseModal title="Help" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col mt-2 divide-y">
        <SettingsToggle settingName="Autocheck" flag={isAutoCheckActive} handleFlag={handleAutoCheckToggle} />
        <HelpButton settingName="Check Square" checkType={CheckType.Square} onPress={handleCheckOrReveal} />
        <HelpButton settingName="Check Clue" checkType={CheckType.Clue} onPress={handleCheckOrReveal} />
        <HelpButton settingName="Check Puzzle" checkType={CheckType.Puzzle} onPress={handleCheckOrReveal} />
      </div>
    </BaseModal>
  );
};
