import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { AlertProvider } from "./context/AlertContext";
import "./index.css";
import { sendToVercelAnalytics } from "./lib/Vitals";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient();
const container = document.getElementById("root");
const root = createRoot(container!);

Sentry.init({
  dsn: "https://ae5ba4d0d1b94b67bbf13276a3abf842@o176350.ingest.sentry.io/4504951821303808",
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0,
});

root.render(
  <React.StrictMode>
    <AlertProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </AlertProvider>
  </React.StrictMode>
);
reportWebVitals(sendToVercelAnalytics);
