import _ from "lodash";
import { Player } from "../types/room";

export class AlertFormatter {
  static playerUpdateAlert(oldUser: Player, newUser: Player): string | undefined {
    if (oldUser === newUser) {
      return;
    }
    let alert = `${oldUser.userName} changed`;
    if (newUser.userName !== oldUser.userName) {
      alert += ` their name to ${newUser.userName}`;
      if (newUser.color !== oldUser.color) {
        alert += ` and`;
      }
    }
    if (newUser.color !== oldUser.color) {
      alert += ` their color to ${_.startCase(newUser.color)}`;
    }
    return alert;
  }
}
