import { Player } from "./room";

export enum ArrowKeyCode {
  Left = "ArrowLeft",
  Right = "ArrowRight",
  Up = "ArrowUp",
  Down = "ArrowDown",
}

export enum Direction {
  Across = "ACROSS",
  Down = "DOWN",
}

export enum CheckType {
  Square = "SQUARE",
  Clue = "CLUE",
  Puzzle = "PUZZLE",
}

export interface CurrentCoordinates {
  x: number;
  y: number;
}

export interface Square {
  xCoord: number;
  yCoord: number;
  black: boolean;
  noEdit: boolean;
  displayNumber?: number;
  acrossClueNumber?: number;
  downClueNumber?: number;
  solution?: string;
  isActive?: boolean;
  isCircled?: boolean;
  isChecked?: boolean;
  isHighlighted?: boolean;
  isIncorrect?: boolean;
  isRevealed?: boolean;
  activeUser?: Player;
  updateUser?: Player;
  value?: string;
}

export interface Clue {
  clueNumber: number;
  clueText: string;
  direction: Direction;
  solution: string;
  isActive?: boolean;
  isChecked?: boolean;
  isRevealed?: boolean;
  isHighlighted?: boolean;
  lastUpdateUser?: string;
}

export interface CrosswordPuzzle {
  id: string;
  author: string;
  copyright: string;
  date: string;
  dayOfWeek: string;
  editor: string;
  notes: string;
  editorNodes: string;
  publisher: string;
  title: string;
  clues: {
    across: Clue[];
    down: Clue[];
  };
  grid: Square[][];
}
