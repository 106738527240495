import classnames from "classnames";
import { CSSProperties } from "react";
import { Color, Player, Room } from "../../types/room";

type Props = {
  xCoord: number;
  yCoord: number;
  black: boolean;
  noEdit: boolean;
  onSelect: (xCoord: number, yCoord: number) => void;
  displayNumber?: number;
  acrossClueNumber?: number;
  downClueNumber?: number;
  isActive?: boolean;
  isActiveClue?: boolean;
  isCircled?: boolean;
  isChecked?: boolean;
  isIncorrect?: boolean;
  isSolvedSuccessfully: boolean;
  isHighlighted?: boolean;
  isRevealed?: boolean;
  value?: string;
  activeUser?: Player;
  updateUser?: Player;
  room?: Room;
  loading?: boolean;
  rowSize: number;
  numRows: number;
};

export const Cell = ({
  xCoord,
  yCoord,
  black = false,
  onSelect,
  displayNumber,
  isActive = false,
  isActiveClue = false,
  isChecked = false,
  isSolvedSuccessfully,
  isIncorrect = false,
  isRevealed = false,
  value,
  activeUser,
  updateUser,
  room,
  loading = false,
  numRows,
  rowSize,
}: Props) => {
  const calculatedCss: CSSProperties = {
    flexBasis: `${((1.0 / rowSize) * 100.0).toFixed(1)}%`,
    height: `${56 - 2 * numRows}px`,
  };

  const sizingClasses = classnames([
    "flex flex-row flex-grow",
    `max-w-[3rem] max-h-[3rem]`,
    "border-zinc-800 border-solid border-r border-b font-bold",
    "cursor-pointer select-none",
  ]);

  const resolveColorCondition = (color: Color, checkUser?: Player) => {
    if (room) {
      let updatePlayer: Player | undefined = room.players.find((user) => user.userId === checkUser?.userId);
      if (updatePlayer) {
        return updatePlayer.color === color;
      }
    }
  };

  const backgroundColorClass = classnames({
    "bg-white": !black && !isActive,
    "bg-blue-300": !black && isActiveClue && !isActive,
    "bg-blue-600": !black && isActiveClue && isActive,
    "bg-gray-300": !black && !isActiveClue && !isActive && isChecked && !isIncorrect,
    "bg-gray-400": !black && isActiveClue && !isActive && isChecked && !isIncorrect,
    "bg-gray-500": !black && isActiveClue && isActive && isChecked && !isIncorrect && !isRevealed,
    "bg-red-300": !black && !isActiveClue && !isActive && isIncorrect,
    "bg-red-400": resolveColorCondition(Color.RED, activeUser),
    "bg-red-500": !black && isActiveClue && !isActive && isIncorrect,
    "bg-red-600": !black && isActiveClue && isActive && isIncorrect,
    "bg-violet-200": !black && !isActiveClue && !isActive && isRevealed,
    "bg-violet-300": !black && isActiveClue && !isActive && isRevealed,
    "bg-violet-500": !black && isActiveClue && isActive && isRevealed,
    "bg-orange-300": resolveColorCondition(Color.ORANGE, activeUser),
    "bg-lime-300": resolveColorCondition(Color.LIME, activeUser),
    "bg-teal-300": resolveColorCondition(Color.TEAL, activeUser),
    "bg-purple-300": resolveColorCondition(Color.PURPLE, activeUser),
    transformColor: isSolvedSuccessfully && !black,
    "bg-zinc-800": black,
  });

  const functionClasses = classnames({
    "animate-pulse border-l border-t": loading,
    "border-t": xCoord === 0,
    "border-l": yCoord === 0,
    "cursor-default": black,
    "cell-fill-animation": !black && !value,
  });

  const textColorClass = classnames({
    "text-white shadowed": isActive && !isSolvedSuccessfully,
    "text-red-600": value && room && resolveColorCondition(Color.RED, updateUser),
    "text-orange-600": value && resolveColorCondition(Color.ORANGE, updateUser),
    "text-lime-600": value && resolveColorCondition(Color.LIME, updateUser),
    "text-teal-600": value && resolveColorCondition(Color.TEAL, updateUser),
    "text-purple-600": value && resolveColorCondition(Color.PURPLE, updateUser),
    "text-transparent bg-clip-text win-gradient growThenShrink": isSolvedSuccessfully,
  });

  if (black) {
    return <div className={classnames(sizingClasses, backgroundColorClass, functionClasses)} style={calculatedCss} />;
  }

  return (
    <button
      className={classnames(sizingClasses, backgroundColorClass, functionClasses)}
      style={calculatedCss}
      onClick={() => {
        if (!black) onSelect(xCoord, yCoord);
      }}
      aria-label={`${xCoord + 1} Across, ${yCoord + 1} Down`}
    >
      <div
        className={classnames("absolute h-0 ml-0.5 text-xxxs lg:text-xxs", {
          "text-white": isActive,
        })}
      >
        {displayNumber && displayNumber > 0 ? displayNumber : ""}
      </div>
      <div className="flex w-full h-full justify-center items-center">
        <p className={classnames("text-center text-[150%] text-3xl", textColorClass)}>{value}</p>
      </div>
    </button>
  );
};
