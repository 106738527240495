import { DateTime } from "luxon";
import { v4 } from "uuid";
import { EXAMPLE_NAMES } from "../constants/strings";
import { CrosswordPuzzle } from "../types/play";
import { Color, Player, Room } from "../types/room";
import { CrosswordStats, Stats } from "../types/stats";

enum LocalStorageKeys {
  ELAPSED_STATE = "elapsedState",
  GAME_STATE = "gameState",
  HIGH_CONTRAST = "highContrast",
  GAME_STATS = "gameStats",
  PLAYER = "player",
  ROOM = "room",
  PUZZLE = "puzzle",
  SWITCH_ENTER_DELETE = "switchEnterAndDeleteKeys",
  TIMER_DISPLAYED = "timerDisplayed"
}

type StoredGameState = {
  isGameWon: boolean;
  puzzle: CrosswordPuzzle;
  usedCheckOrReveal: boolean;
};

type ElapsedState = {
  timeElapsed: number;
  puzzleDate: string;
}

export const saveGameStateToLocalStorage = (gameState: StoredGameState): void => {
  localStorage.setItem(LocalStorageKeys.GAME_STATE, JSON.stringify(gameState));
};

export const loadGameStateFromLocalStorage = (): StoredGameState | null => {
  const state = localStorage.getItem(LocalStorageKeys.GAME_STATE);
  return state ? JSON.parse(state) : null;
};

export const saveTimeElapsedInLocalStorage = (
  timeElapsed: number,
  puzzleDate: DateTime,
): void => {
  localStorage.setItem(LocalStorageKeys.ELAPSED_STATE, JSON.stringify({
    timeElapsed: timeElapsed,
    puzzleDate: puzzleDate.toISODate()
  }))
};

export const loadElapsedStateFromLocalStorage = (): ElapsedState | null => {
  const state = localStorage.getItem(LocalStorageKeys.ELAPSED_STATE);
  return state ? JSON.parse(state) : null;
};

export const saveStatsToLocalStorage = (gameStats: CrosswordStats) => {
  localStorage.setItem(LocalStorageKeys.GAME_STATS, JSON.stringify(gameStats));
};

export const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(LocalStorageKeys.GAME_STATS);
  const crosswordStats = stats ? (JSON.parse(stats) as CrosswordStats) : null;
  if (crosswordStats === null) {
    return null;
  }
  return isValidStats(crosswordStats.soloStats) ? crosswordStats : null;
};

function isValidStats(stats: any): stats is Stats {
  return (stats as Stats).winDistribution !== undefined;
}

export const setStoredIsHighContrastMode = (isHighContrast: boolean) => {
  if (isHighContrast) {
    localStorage.setItem(LocalStorageKeys.HIGH_CONTRAST, "1");
  } else {
    localStorage.removeItem(LocalStorageKeys.HIGH_CONTRAST);
  }
};

export const getStoredIsHighContrastMode = () => {
  const highContrast = localStorage.getItem(LocalStorageKeys.HIGH_CONTRAST);
  return highContrast === "1";
};

export const setStoredSwitchEnterDeleteKeys = (switchEnterDelete: boolean) => {
  if (switchEnterDelete) {
    localStorage.setItem(LocalStorageKeys.SWITCH_ENTER_DELETE, "1");
  } else {
    localStorage.removeItem(LocalStorageKeys.SWITCH_ENTER_DELETE);
  }
};

export const getStoredSwitchEnterDeleteKeys = () => {
  const switchEnterDelete = localStorage.getItem(LocalStorageKeys.SWITCH_ENTER_DELETE);
  return switchEnterDelete === "1";
};

export const setStoredIsTimerDisplayed = (timerDisplayed: boolean) => {
  if (timerDisplayed) {
    localStorage.setItem(LocalStorageKeys.TIMER_DISPLAYED, "1");
  } else {
    localStorage.removeItem(LocalStorageKeys.TIMER_DISPLAYED);
  }
};

export const getStoredIsTimerDisplayed = () => {
  const timerDisplayed = localStorage.getItem(LocalStorageKeys.TIMER_DISPLAYED);
  return timerDisplayed === "1";
};

export const loadOrCreatePlayerFromLocalStorage = (): Player => {
  const playerString = localStorage.getItem(LocalStorageKeys.PLAYER);
  if (playerString) {
    return JSON.parse(playerString);
  }
  const userPlayer = {
    userId: v4(),
    userName: EXAMPLE_NAMES[Math.floor(Math.random() * EXAMPLE_NAMES.length)],
    color: Object.values(Color)[Math.floor(Math.random() * Object.keys(Color).length)] as Color,
  };
  localStorage.setItem(LocalStorageKeys.PLAYER, JSON.stringify(userPlayer));
  return userPlayer;
};

export const savePlayerToLocalStorage = (player: Player) => {
  localStorage.setItem(LocalStorageKeys.PLAYER, JSON.stringify(player));
};

export const loadRoomFromLocalStorage = () => {
  const room = localStorage.getItem(LocalStorageKeys.ROOM);
  return room ? (JSON.parse(room) as Room) : undefined;
};

export const saveRoomToLocalStorage = (room: Room) => {
  localStorage.setItem(LocalStorageKeys.ROOM, JSON.stringify(room));
};
