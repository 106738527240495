import { CrosswordPuzzle, Square } from "./play";
import { ChatMessage, Player, Room } from "./room";

export enum MessageType {
  ChatMessage = "CHAT_MESSAGE",
  JoinRoomRequest = "JOIN_ROOM_REQUEST",
  GameUpdate = "GAME_UPDATE",
  PlayerJoinedRoom = "PLAYER_JOINED_ROOM",
  PlayerLeftRoom = "PLAYER_LEFT_ROOM",
  PuzzleUpdate = "PUZZLE_UPDATE",
  RoomUpdate = "ROOM_UPDATE",
  SquareUpdate = "SQUARE_UPDATE",
}

export interface Message {
  alert?: string;
  messageType: MessageType;
  sender: Player;
  room: Room;
}

export interface ChatMessageEvent extends Message {
  chatMessage: ChatMessage;
}

export interface GameUpdateEvent extends Message {
  puzzle: CrosswordPuzzle;
  room: Room;
}

export interface RoomJoinEvent extends Message {}

export interface RoomUpdateEvent extends Message {
  room: Room;
}

export interface SquareUpdateEvent extends Message {
  square: Square;
}

export interface PuzzleUpdateEvent extends Message {
  puzzle: CrosswordPuzzle;
}

export interface UpdateAction {
  alert?: string;
  chatMessage?: ChatMessage;
  puzzle?: CrosswordPuzzle;
  room?: Room;
  sendMessage?: string;
}
