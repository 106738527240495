export interface Room {
  roomId: string;
  createdTime: number;
  players: Player[];
}

export interface Player {
  color?: Color;
  online?: boolean;

  userId: string;
  userName: string;
}

export interface ChatMessage {
  sender: Player;
  message: string;
  sendTime: number;
}

export enum Color {
  RED = "red",
  ORANGE = "orange",
  LIME = "lime",
  TEAL = "teal",
  PURPLE = "purple",
}
