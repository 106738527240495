import { useState } from "react";
import { BaseModal } from "./BaseModal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmitFeedback: (feedback: string) => void;
};

export const FeedbackModal = ({ isOpen, handleClose, handleSubmitFeedback }: Props) => {
  const [feedback, setFeedback] = useState("");

  return (
    <BaseModal title="Share Your Feedback!" isOpen={isOpen} handleClose={handleClose}>
      <div>
        <p className="mb-3 text-sm text-gray-500 dark:text-gray-300">
          Found a bug? Have an improvement? Submit them so we can make Crossnoodle better!
        </p>
      </div>
      <div>
        <textarea
          className="my-3 bg-sky-100"
          id="feedbackArea"
          name="feedbackArea"
          rows={3}
          cols={30}
          onChange={(event) => setFeedback(event.target.value)}
        />
      </div>
      <div
        className={"p-1 px-3 bg-sky-300 rounded-full duration-300 cursor-pointer bg-sky-500 text-white"}
        onClick={() => handleSubmitFeedback(feedback)}
      >
        <p>Submit</p>
      </div>
    </BaseModal>
  );
};
