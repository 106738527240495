import { Clue, CrosswordPuzzle, Direction, Square } from "../types/play";

function getClueDirectionalNumber(
  puzzleData: any,
  direction: Direction,
  gridIndex: number,
  clueSearchDepth: number
): number | undefined {
  for (
    let i = gridIndex;
    i >= gridIndex - clueSearchDepth;
    i -= direction === Direction.Across ? 1 : puzzleData.size.rows
  ) {
    const gridNumber: number = puzzleData.gridnums[i];
    if (puzzleData.grid[i] === ".") {
      return undefined;
    }
    if (
      gridNumber !== 0 &&
      getAvailableClueNumbers(puzzleData.clues[direction.toLocaleLowerCase()]).indexOf(gridNumber) >= 0
    ) {
      return puzzleData.gridnums[i];
    }
  }
  // Don't throw the below error as some grids may only be for across or down
  // throw new Error("Unable to find any grid number for this index");
}

function getAvailableClueNumbers(clues: String[]): number[] {
  return clues.map((clue: String): number => {
    return Number(clue.split(".")[0]);
  });
}

export function parseCrossword(puzzleData: any): CrosswordPuzzle {
  console.log(puzzleData);
  return {
    id: "testId",
    author: puzzleData.author,
    copyright: puzzleData.copyright,
    date: puzzleData.date,
    dayOfWeek: puzzleData.dow,
    editor: puzzleData.editor,
    notes: puzzleData.notepad,
    editorNodes: puzzleData.jnotes,
    publisher: puzzleData.publisher,
    title: puzzleData.title,
    clues: {
      across: puzzleData.clues.across.map((clue: string, i: number): Clue => {
        return {
          clueNumber: Number(clue.split(".")[0]),
          clueText: clue.substring(clue.indexOf(".") + 1, clue.length).trim(),
          direction: Direction.Across,
          solution: puzzleData.answers.across[i],
        };
      }),
      down: puzzleData.clues.down.map((clue: string, i: number): Clue => {
        return {
          clueNumber: Number(clue.split(".")[0]),
          clueText: clue.substring(clue.indexOf(".") + 1, clue.length).trim(),
          direction: Direction.Down,
          solution: puzzleData.answers.down[i],
        };
      }),
    },
    grid: Array(puzzleData.size.rows)
      .fill(null)
      .map((_, i: number): Square[] =>
        Array(puzzleData.size.cols)
          .fill(null)
          .map((_, j: number): Square => {
            const gridIndex = i * puzzleData.size.rows + j;
            if (puzzleData.grid[gridIndex] === ".") {
              return {
                xCoord: i,
                yCoord: j,
                black: true,
                noEdit: true,
              };
            }
            return {
              xCoord: i,
              yCoord: j,
              black: false,
              noEdit: false,
              displayNumber: puzzleData.gridnums[gridIndex],
              acrossClueNumber: getClueDirectionalNumber(puzzleData, Direction.Across, gridIndex, j),
              downClueNumber: getClueDirectionalNumber(puzzleData, Direction.Down, gridIndex, i * puzzleData.size.rows),
              solution: puzzleData.grid[gridIndex],
            };
          })
      ),
  };
}
