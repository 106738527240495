import {
  BEST_STREAK_TEXT,
  CURRENT_STREAK_TEXT,
  GAMES_WON,
  SUCCESS_RATE_TEXT,
  TOTAL_GAMES_TEXT,
} from "../../constants/strings";
import { Stats } from "../../types/stats";
import _ from "lodash";

type Props = {
  stats: Stats;
};

const StatItem = ({ label, value }: { label: string; value: string | number }) => {
  return (
    <div className="items-center justify-center m-1 w-1/4 dark:text-white">
      <div className="text-3xl font-bold">{value}</div>
      <div className="text-xs">{label}</div>
    </div>
  );
};

export const StatBar = ({ stats }: Props) => {

  // Get the total games won across all days
  const totalGamesWon = _.sum(stats.winDistribution.map((x) => x.gamesWon));
  // Get the total games played across all days
  const totalGamesPlayed = _.sum(stats.winDistribution.map((x) => x.gamesPlayed));
  // Get the success rate of wins across all games
  const successRate = Math.round((100 * totalGamesWon) / Math.max(totalGamesPlayed, 1));

  return (
    <div className="flex justify-center my-2">
      <StatItem label={TOTAL_GAMES_TEXT} value={totalGamesPlayed ? totalGamesPlayed : 0} />
      <StatItem label={GAMES_WON} value={totalGamesWon ? totalGamesWon : 0} />
      <StatItem label={SUCCESS_RATE_TEXT} value={`${successRate}%`} />
      <StatItem label={CURRENT_STREAK_TEXT} value={stats.currentStreak ? stats.currentStreak : 0} />
      <StatItem label={BEST_STREAK_TEXT} value={stats.bestStreak ? stats.bestStreak : 0} />
    </div>
  );
};
