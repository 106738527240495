import { BaseModal } from "./BaseModal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleFlagClue: () => void;
};

export const FlagClueModal = ({ isOpen, handleClose, handleFlagClue }: Props) => {
  return (
    <BaseModal title="Flag this clue?" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-row justify-evenly">
        <button
          type="button"
          className="mt-2 mx-2 px-8 py-2 rounded-md border border-transparent shadow-sm bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          onClick={() => {
            handleFlagClue();
            handleClose();
          }}
        >
          Yes
        </button>
        <button
          type="button"
          className="mt-2 mx-2 px-8 py-2 rounded-md border border-transparent shadow-sm  bg-indigo-900 text-base font-medium text-white hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
          onClick={() => handleClose()}
        >
          No
        </button>
      </div>
    </BaseModal>
  );
};
