import { useEffect } from "react";
import { DELETE_TEXT, ENTER_TEXT } from "../../constants/strings";
import { ArrowKeyCode } from "../../types/play";
import { Key } from "./Key";

type Props = {
  onChar: (value: string) => void;
  onDelete: () => void;
  onEnter: () => void;
  onNavigate: (arrowKey: string) => void;
  isModalOpen: boolean;
  switchEnterDeleteKeys: boolean;
};

const ENTER_VALUE: string = "ENTER";
const DELETE_VALUE: string = "DELETE";

const localeAwareUpperCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toUpperCase();
};

export const Keyboard = ({ onChar, onDelete, onEnter, onNavigate, isModalOpen, switchEnterDeleteKeys }: Props) => {
  const onClick = (value: string) => {
    if (value === ENTER_VALUE) {
      onEnter();
    } else if (value === DELETE_VALUE) {
      onDelete();
    } else {
      onChar(value);
    }
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (!isModalOpen) {
        if (e.code === "Backspace" || e.code === "Delete") {
          onDelete();
        } else if (Object.values(ArrowKeyCode).includes(e.code as ArrowKeyCode)) {
          onNavigate(e.code);
        } else {
          const key = localeAwareUpperCase(e.key);
          if (key.length === 1 && key >= "A" && key <= "Z") {
            onChar(key);
          }
        }
      }
    };
    window.addEventListener("keyup", listener);
    return () => {
      window.removeEventListener("keyup", listener);
    };
  }, [onDelete, onChar, onNavigate, isModalOpen]);

  return (
    <div>
      <div className="flex justify-center mb-1">
        {["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"].map((key) => (
          <Key value={key} key={key} onClick={onClick} />
        ))}
      </div>
      <div className="flex justify-center mb-1 mx-1">
        {["A", "S", "D", "F", "G", "H", "J", "K", "L"].map((key) => (
          <Key value={key} key={key} onClick={onClick} />
        ))}
      </div>
      <div className="flex flex-row justify-center">
        <Key width={60} value={switchEnterDeleteKeys ? DELETE_VALUE : ENTER_VALUE} onClick={onClick}>
          {switchEnterDeleteKeys ? DELETE_TEXT : ENTER_TEXT}
        </Key>
        {["Z", "X", "C", "V", "B", "N", "M"].map((key) => (
          <Key value={key} key={key} onClick={onClick} />
        ))}
        <Key width={60} value={switchEnterDeleteKeys ? ENTER_VALUE : DELETE_VALUE} onClick={onClick}>
          {switchEnterDeleteKeys ? ENTER_TEXT : DELETE_TEXT}
        </Key>
      </div>
    </div>
  );
};
