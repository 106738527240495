import { CrosswordStats, DailyStats, Stats } from "../types/stats";
import { loadStatsFromLocalStorage, saveStatsToLocalStorage } from "./LocalStorage";
import { DateTime } from "luxon";

export const addStatsForSolvingGame = (gameStats: CrosswordStats, dayOfWeekAsInt: number, puzzleDate: string) => {
  const stats: Stats = { ...gameStats.soloStats };

  // Update Day-Of-Week stats
  stats.winDistribution[dayOfWeekAsInt].gamesWon += 1;
  let lastDayOfWeekSolvedDate = stats.winDistribution[dayOfWeekAsInt].lastSolved;
  if (
    lastDayOfWeekSolvedDate &&
    DateTime.fromISO(lastDayOfWeekSolvedDate) === DateTime.fromISO(puzzleDate).minus({ weeks: 1 })
  ) {
    stats.winDistribution[dayOfWeekAsInt].currentStreak += 1;
  } else {
    stats.winDistribution[dayOfWeekAsInt].currentStreak = 1;
  }
  if (stats.winDistribution[dayOfWeekAsInt].bestStreak < stats.winDistribution[dayOfWeekAsInt].currentStreak) {
    stats.winDistribution[dayOfWeekAsInt].bestStreak = stats.winDistribution[dayOfWeekAsInt].currentStreak;
  }

  // Update General Stats
  let lastSolvedDate = stats.lastSolved;
  console.log(lastSolvedDate);
  console.log(DateTime.fromISO(lastSolvedDate!));
  console.log(DateTime.fromISO(puzzleDate).minus({ days: 1 }));

  if (lastSolvedDate && DateTime.fromISO(lastSolvedDate).toISODate() === DateTime.fromISO(puzzleDate).minus({ days: 1 }).toISODate()) {
    stats.currentStreak += 1;
  } else {
    stats.currentStreak = 1;
  }
  if (stats.bestStreak < stats.currentStreak) {
    stats.bestStreak = stats.currentStreak;
  }

  // Update Last Solved Dates
  stats.lastSolved = puzzleDate;
  stats.winDistribution[dayOfWeekAsInt].lastSolved = puzzleDate;

  gameStats.soloStats = stats;
  saveStatsToLocalStorage(gameStats);
  return stats;
};

export const addStatsForStartingGame = (gameStats: CrosswordStats, dayOfWeekAsInt: number) => {
  const stats: Stats = { ...gameStats.soloStats };
  stats.winDistribution[dayOfWeekAsInt].gamesPlayed += 1; // Update Day-Of-Week stats
  gameStats.soloStats = stats;
  saveStatsToLocalStorage(gameStats);
  return stats;
};

export const resetStats = () => {
  saveStatsToLocalStorage(defaultStats);
  return defaultStats;
};

let defaultStats: CrosswordStats;
defaultStats = {
  soloStats: {
    winDistribution: Array.from(new Array(7), () => {
      return {
        gamesPlayed: 0,
        gamesWon: 0,
        bestStreak: 0,
      } as DailyStats;
    }),
    currentStreak: 0,
    bestStreak: 0,
  },
  coopStats: [],
};

export const loadStats = () => {
  const stats = loadStatsFromLocalStorage();
  return stats || defaultStats;
};
