import { Clue, Direction, Square } from "../../types/play";
import { Room } from "../../types/room";
import { Cell } from "./Cell";

type Props = {
  currentClue: Clue | undefined;
  direction: Direction;
  isSolvedSuccessfully: boolean;
  onSelect: (xCoord: number, yCoord: number) => void;
  numRows: number;
  room?: Room;
  rowNumber: number;
  selectedSquare: Square;
  squares: Square[];
};

export const Row = ({
  currentClue,
  direction,
  isSolvedSuccessfully,
  onSelect,
  numRows,
  room,
  rowNumber,
  selectedSquare,
  squares,
}: Props) => {
  return (
    <div className="flex flex-row justify-center">
      {squares.map((square: Square, j: number) => (
        <Cell
          key={`${rowNumber}.${j}`}
          xCoord={square.xCoord}
          yCoord={square.yCoord}
          black={square.black}
          value={square.value}
          noEdit={square.black || square.isRevealed! || square.isChecked!}
          onSelect={onSelect}
          displayNumber={square.displayNumber}
          isActive={square.xCoord === selectedSquare.xCoord && square.yCoord === selectedSquare.yCoord}
          isActiveClue={
            currentClue
              ? direction === Direction.Across
                ? square.acrossClueNumber === currentClue.clueNumber
                : square.downClueNumber === currentClue.clueNumber
              : false
          }
          isCircled={square.isCircled}
          isChecked={square.isChecked}
          isSolvedSuccessfully={isSolvedSuccessfully}
          isHighlighted={square.isHighlighted}
          isIncorrect={square.isIncorrect}
          isRevealed={square.isRevealed}
          activeUser={square.activeUser}
          updateUser={square.updateUser}
          room={room}
          numRows={numRows}
          rowSize={squares.length}
        />
      ))}
    </div>
  );
};
