import classNames from "classnames";
import { CrosswordUtils } from "../../lib/CrosswordUtils";

type Props = {
  index: number;
  gamesTotal: number;
  gamesWon: number;
  isCurrentDayStatRow: boolean;
  isGameWon: boolean;
  lossSize: number;
  winSize: number;
};

export const Progress = ({ index, isCurrentDayStatRow, isGameWon, gamesTotal, gamesWon, lossSize, winSize }: Props) => {
  return (
    <div className="flex flex-row justify-left m-1">
      <div className="justify-left">{CrosswordUtils.getDayOfWeekFromInt(index)}</div>
      <div className="w-full ml-2">
        {gamesTotal > 0 ? (
          <div className="flex flex-row">
            <div
              style={{ width: `${winSize}%` }}
              className={classNames("text-xs text-white pt-0.5", {
                "bg-blue-600": isCurrentDayStatRow,
                "bg-gray-500": !isCurrentDayStatRow,
                "text-right": gamesWon >= 0 && gamesWon < gamesTotal,
                "text-center": gamesWon === gamesTotal,
              })}
            >
              {gamesWon > 0 ? (gamesWon === gamesTotal ? `${gamesWon}/${gamesTotal}` : `${gamesWon}/`) : ``}
            </div>
            <div
              style={{ width: `${lossSize}%` }}
              className={classNames("text-xs text-white pt-0.5", {
                "bg-blue-800": isCurrentDayStatRow,
                "bg-gray-600": !isCurrentDayStatRow,
                "text-left": gamesWon > 0 && gamesWon < gamesTotal,
                "text-center": gamesWon <= 0,
              })}
            >
              {gamesWon > 0 ? (gamesWon === gamesTotal ? `` : `${gamesTotal}`) : `${gamesWon}/${gamesTotal}`}
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
