import { CheckType } from "../../../types/play";

type Props = {
  settingName: string;
  checkType: CheckType;
  onPress: (checkType: CheckType, reveal: boolean) => void;
  description?: string;
};

export const HelpButton = ({ settingName, checkType, onPress, description }: Props) => {
  const classes = "p-1 px-3 self-center bg-sky-300 rounded-full duration-300 ease-in-out cursor-pointer";
  return (
    <>
      <div className="flex flex-row justify-between gap-4 py-3">
        <div className="text-left self-center text-gray-500 dark:text-gray-300 my-1">
          <p className="leading-none">{settingName}</p>
          {description && <p className="text-xs mt-1 text-gray-500 dark:text-gray-300">{description}</p>}
        </div>
        <div className={classes} onClick={() => onPress(checkType, false)}>
          <p>Check</p>
        </div>
        <div className={classes + " bg-sky-500 text-white"} onClick={() => onPress(checkType, true)}>
          <p>Reveal</p>
        </div>
      </div>
    </>
  );
};
