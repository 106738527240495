import React from "react";
import { Clue, Direction, Square } from "../../types/play";
import { Room } from "../../types/room";
import { Row } from "./Row";

type Props = {
  currentClue?: Clue;
  direction: Direction;
  grid: Square[][];
  isSolvedSuccessfully: boolean;
  room?: Room;
  onSelect: (xCoord: number, yCoord: number) => void;
  selectedSquare: Square;
};

export const Grid = ({ currentClue, direction, isSolvedSuccessfully, grid, onSelect, room, selectedSquare }: Props) => {
  return (
    <>
      {grid.map((row: Square[], i: number) => (
        <Row
          currentClue={currentClue}
          key={i}
          direction={direction}
          isSolvedSuccessfully={isSolvedSuccessfully}
          numRows={grid.length}
          onSelect={onSelect}
          room={room}
          rowNumber={i}
          selectedSquare={selectedSquare}
          squares={row}
        />
      ))}
    </>
  );
};
