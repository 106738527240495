import { Clue, CrosswordPuzzle, Direction, Square } from "../types/play";

export class CrosswordUtils {
  static checkOrRevealSquare(puzzle: CrosswordPuzzle, square: Square, reveal: boolean = false): Square {
    return {
      ...square,
      isChecked: !!square.value,
      // Checked -> Only if the square had a value
      isIncorrect: square.value && !reveal ? square.value !== square.solution : false,
      // Incorrect -> Only if the square had a value and not revealed
      isRevealed: reveal,
      // Revealed -> If user hit reveal
      noEdit: reveal || square.value === square.solution,
      // No Edit -> If user revealed the square or the check was correct
      value: reveal ? square.solution : square.value,
      // Update the value only if revealed
    };
  }
  static isClueNumberDefined(direction: Direction, puzzle: CrosswordPuzzle, currentSquare: Square): boolean {
    if (direction === Direction.Across) {
      return currentSquare.acrossClueNumber !== undefined;
    } else {
      return currentSquare.downClueNumber !== undefined;
    }
  }
  static isPuzzleFilled(puzzle: CrosswordPuzzle): boolean {
    for (let i = 0; i < puzzle.grid.length; i++) {
      for (let j = 0; j < puzzle.grid[i].length; j++) {
        if (!this.isSquareCompleted(this.getSquare(puzzle, i, j))) {
          return false;
        }
      }
    }
    return true;
  }
  static isPuzzleCompleted(puzzle: CrosswordPuzzle): boolean {
    for (let i = 0; i < puzzle.grid.length; i++) {
      for (let j = 0; j < puzzle.grid[i].length; j++) {
        let square = this.getSquare(puzzle, i, j);
        if (!this.isSquareCompleted(square) || square.value !== square.solution) {
          return false;
        }
      }
    }
    return true;
  }
  static isSquareCompleted(square: Square): boolean {
    return square.black || (square.value !== undefined && square.value.length > 0);
  }
  static isSquareNavigable(square: Square | undefined, override: boolean = false): boolean {
    if (square) {
      return (
        !square.black && (override || square.value === undefined || square.value === "" || square.value.length < 1)
      );
    }
    return false;
  }
  static getClue(direction: Direction, puzzle: CrosswordPuzzle, square: Square): Clue | undefined {
    if (direction === Direction.Across) {
      return puzzle.clues.across.find((clue) => clue.clueNumber === square.acrossClueNumber);
    } else {
      return puzzle.clues.down.find((clue) => clue.clueNumber === square.downClueNumber);
    }
  }
  static getClueIndex(direction: Direction, puzzle: CrosswordPuzzle, square: Square): number {
    if (direction === Direction.Across) {
      return puzzle.clues.across.findIndex((clue) => clue.clueNumber === square.acrossClueNumber);
    } else {
      return puzzle.clues.down.findIndex((clue) => clue.clueNumber === square.downClueNumber);
    }
  }
  static getIntDayOfWeekFromString(dayOfWeek: String): number {
    switch (dayOfWeek.toUpperCase()) {
      case "MONDAY":
        return 0;
      case "TUESDAY":
        return 1;
      case "WEDNESDAY":
        return 2;
      case "THURSDAY":
        return 3;
      case "FRIDAY":
        return 4;
      case "SATURDAY":
        return 5;
      case "SUNDAY":
        return 6;
      default:
        return -1;
    }
  }
  static getDayOfWeekFromInt(dayOfWeek: number): String {
    switch (dayOfWeek) {
      case 0:
        return "MONDAY";
      case 1:
        return "TUESDAY";
      case 2:
        return "WEDNESDAY";
      case 3:
        return "THURSDAY";
      case 4:
        return "FRIDAY";
      case 5:
        return "SATURDAY";
      case 6:
        return "SUNDAY";
      default:
        return "UNKNOWN";
    }
  }
  static getSquare(puzzle: CrosswordPuzzle, xCoord: number, yCoord: number): Square {
    return puzzle.grid[xCoord][yCoord];
  }
  static getSquaresForClue(puzzle: CrosswordPuzzle, clueNumber: number, direction: Direction): Square[] {
    let [found, i, j] = [false, 0, 0];
    const results: Square[] = [];
    if (direction === Direction.Across) {
      while (!found && i < puzzle.grid.length) {
        for (j = 0; j < puzzle.grid[i].length; j++) {
          if (this.getSquare(puzzle, i, j).acrossClueNumber === clueNumber) {
            results.push(this.getSquare(puzzle, i, j));
            found = true;
          }
        }
        i++;
      }
    } else if (direction === Direction.Down) {
      while (!found && j < puzzle.grid[0].length) {
        for (i = 0; i < puzzle.grid.length; i++) {
          if (this.getSquare(puzzle, i, j).downClueNumber === clueNumber) {
            results.push(this.getSquare(puzzle, i, j));
            found = true;
          }
        }
        j++;
      }
    }
    return results;
  }
  static getSquareAndDirectionFromClue(
    puzzle: CrosswordPuzzle,
    clueNumber: number,
    direction: Direction
  ): [Square, Direction] {
    let clueSquares: Square[] = this.getSquaresForClue(puzzle, clueNumber, direction);
    for (let square of clueSquares) {
      if (this.isSquareNavigable(square)) {
        return [square, direction];
      }
    }
    // If they're all filled, return the first
    return [clueSquares[0], direction];
  }
  static getSquaresForNextClue(
    puzzle: CrosswordPuzzle,
    direction: Direction,
    currentSquare: Square,
    movement: number
  ): [Square[], Direction] {
    let clueIndex = this.getClueIndex(direction, puzzle, currentSquare);
    const nextClueIndex = clueIndex ? clueIndex + movement : 1;
    if (direction === Direction.Across) {
      if (nextClueIndex < puzzle.clues.across.length) {
        return [
          this.getSquaresForClue(puzzle, puzzle.clues.across[nextClueIndex].clueNumber, Direction.Across),
          Direction.Across,
        ];
      } else if (nextClueIndex < 1) {
        return [
          this.getSquaresForClue(
            puzzle,
            puzzle.clues.across[puzzle.clues.across.length - 1].clueNumber,
            Direction.Across
          ),
          Direction.Across,
        ];
      } else {
        return [this.getSquaresForClue(puzzle, puzzle.clues.down[0].clueNumber, Direction.Down), Direction.Down];
      }
    } else {
      if (nextClueIndex < puzzle.clues.down.length) {
        return [
          this.getSquaresForClue(puzzle, puzzle.clues.down[nextClueIndex].clueNumber, Direction.Down),
          Direction.Down,
        ];
      } else if (nextClueIndex < 1) {
        return [
          this.getSquaresForClue(puzzle, puzzle.clues.down[puzzle.clues.down.length - 1].clueNumber, Direction.Down),
          Direction.Down,
        ];
      } else {
        return [this.getSquaresForClue(puzzle, puzzle.clues.across[0].clueNumber, Direction.Across), Direction.Across];
      }
    }
  }
  static getSquareInDirection(
    direction: Direction,
    puzzle: CrosswordPuzzle,
    currentSquare: Square,
    movement: number
  ): Square | undefined {
    let xCoord = direction === Direction.Down ? currentSquare.xCoord + movement : currentSquare.xCoord;
    let yCoord = direction === Direction.Across ? currentSquare.yCoord + movement : currentSquare.yCoord;
    if (xCoord >= puzzle.grid.length) {
      return undefined;
    } else if (xCoord < 0) {
      return undefined;
    }
    if (yCoord >= puzzle.grid[0].length) {
      return undefined;
    } else if (yCoord < 0) {
      return undefined;
    }
    return puzzle.grid[xCoord][yCoord];
  }
  static determineNextClueAndDirection(
    direction: Direction,
    puzzle: CrosswordPuzzle,
    currentSquare: Square,
    backwards: boolean = false,
    overrideFilled: boolean = false
  ): [Square, Direction] {
    /*
      For each following clue, check each of the squares in that clue to find the next empty one
     */
    let [nextClueSquares, nextDirection] = this.getSquaresForNextClue(
      puzzle,
      direction,
      currentSquare,
      backwards ? -1 : 1
    );
    while (nextClueSquares) {
      for (let nextSquare of nextClueSquares) {
        if (this.isSquareNavigable(nextSquare, overrideFilled)) {
          return [nextSquare, nextDirection];
        }
      }
      [nextClueSquares, nextDirection] = this.getSquaresForNextClue(
        puzzle,
        nextDirection,
        nextClueSquares[0],
        backwards ? -1 : 1
      );
    }
    return [this.getSquare(puzzle, 0, 0), Direction.Across];
  }
  static determineNextSquareAndDirection(
    direction: Direction,
    puzzle: CrosswordPuzzle,
    currentSquare: Square,
    backwards: boolean = false,
    overrideFilled: boolean = false
  ): [Square, Direction] {
    /*
      Get the next immediate square: check it has no entries in it and that it's part of the same clue
     */
    let immediateNextSquare: Square | undefined = this.getSquareInDirection(
      direction,
      puzzle,
      currentSquare,
      backwards ? -1 : 1
    );
    if (
      immediateNextSquare &&
      this.isSquareNavigable(immediateNextSquare, overrideFilled) &&
      ((direction === Direction.Across && currentSquare.acrossClueNumber === immediateNextSquare.acrossClueNumber) ||
        (direction === Direction.Down && currentSquare.downClueNumber === immediateNextSquare.downClueNumber))
    ) {
      return [immediateNextSquare, direction];
    }
    /*
      Check all the squares for this clue, starting from the first
     */
    let currentClueSquares: Square[] = this.getSquaresForClue(
      puzzle,
      direction === Direction.Across ? currentSquare.acrossClueNumber! : currentSquare.downClueNumber!,
      direction
    );
    /*
      If we're going backwards, and we're at the first square:
        1) If the clue is empty, go to the last square of the previous clue
        2) Otherwise, go to the end of the same clue
    */
    if (
      backwards &&
      currentSquare.xCoord === currentClueSquares.at(0)?.xCoord &&
      currentSquare.yCoord === currentClueSquares.at(0)?.yCoord
    ) {
      if (currentClueSquares.filter((sq) => !sq.value || sq.value.length < 1).length === currentClueSquares.length) {
        let [previousClue, previousDirection] = this.getSquaresForNextClue(
          puzzle,
          direction,
          currentSquare,
          backwards ? -1 : 1
        );
        return [previousClue.at(previousClue.length - 1)!, previousDirection];
      } else {
        let nextSquare = currentClueSquares.at(currentClueSquares.length - 1);
        return [nextSquare!, direction];
      }
    }
    // Otherwise continue
    for (let square of currentClueSquares) {
      if (this.isSquareNavigable(square, overrideFilled)) {
        return [square, direction];
      }
    }
    // For each following clue, get the next square (changing direction at the end of the puzzle)
    return this.determineNextClueAndDirection(direction, puzzle, currentSquare, backwards, overrideFilled);
  }
  static setSquares(puzzle: CrosswordPuzzle, updateSquares: Square[]): CrosswordPuzzle {
    const grid = puzzle.grid;
    updateSquares.forEach((square) => (grid[square.xCoord][square.yCoord] = square));
    return {
      ...puzzle,
      grid: grid,
    };
  }
}
