import classnames from "classnames";
import { CSSProperties } from "react";

type Props = {
  rowSize: number;
  numRows: number;
};

export const LoadingCell = ({ rowSize }: Props) => {
  const calculatedCss: CSSProperties = {
    animationName: `loadingCellPulse`,
    animationDelay: `${Math.random() * 2000}ms`,
    animationDuration: "2s",
    animationIterationCount: "infinite",
    animationTimingFunction: "cubic-bezier(0, 0, 1, 1)",
    flexBasis: `${((1.0 / rowSize) * 100.0).toFixed(1)}%`,
  };

  const classes = classnames([
    "flex flex-row flex-grow",
    `max-w-[3rem] max-h-[3rem] min-h-[2.5rem] min-w-[2.5rem]`,
    "bg-white border-l border-t border-zinc-800 border-solid border-r border-b font-bold",
    "cursor-pointer select-none",
  ]);

  return (
    <div className={classes} style={calculatedCss}>
      <div className="flex flex-grow justify-center items-center w-full">
        <p className="text-center text-[150%] text-3xl"></p>
      </div>
    </div>
  );
};
