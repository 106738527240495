import classnames from "classnames";
import { Color, Player } from "../../../types/room";

type Props = {
  user: Player;
  handleUserUpdate: (userName: string, color: Color | undefined) => void;
};

export const UserColorPicker = ({ user, handleUserUpdate }: Props) => {
  return (
    <div className="mt-2 text-left pt-2">
      <p className="text-gray-500 dark:text-white">User Color</p>
      <div className="flex my-2 justify-around">
        {Object.values(Color).map((color: Color) => {
          const classes = classnames("h-8 w-8", {
            "border-4 border-solid border-black dark:border-gray-200": user.color === color,
            "bg-red-600": color === Color.RED,
            "bg-orange-600": color === Color.ORANGE,
            "bg-lime-600": color === Color.LIME,
            "bg-teal-600": color === Color.TEAL,
            "bg-purple-600": color === Color.PURPLE,
          });
          return <button key={color} className={classes} onClick={() => handleUserUpdate(user.userName, color)} />;
        })}
      </div>
    </div>
  );
};
