import { ArrowCircleRightIcon } from "@heroicons/react/outline";
import classnames from "classnames";
import { useEffect, useState } from "react";
import { ChatMessage, Color, Player, Room } from "../../../types/room";
import { BaseModal } from "../BaseModal";

type Props = {
  chatMessages: ChatMessage[];
  isOpen: boolean;
  handleClose: () => void;
  handleSendChatMessage: (chatMessage: string) => void;
  room: Room | undefined;
  user: Player;
};

export const ChatModal = ({ chatMessages, isOpen, handleClose, handleSendChatMessage, room, user }: Props) => {
  const [newMessage, setNewMessage] = useState("");

  const clickSendMessage = () => {
    handleSendChatMessage(newMessage);
    setNewMessage("");
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (isOpen && e.code === "Enter") {
        clickSendMessage();
      }
    };
    window.addEventListener("keyup", listener);
    return () => {
      window.removeEventListener("keyup", listener);
    };
  }, [isOpen]);

  return (
    <BaseModal title="Chat" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col max-h-[50vh]">
        <div id="messageWindow" className="flex flex-col-reverse overflow-y-auto">
          {chatMessages
            .slice(0)
            .reverse()
            .map((message, idx) => {
              const sendingUser = room?.players.filter((player) => player.userId === message.sender.userId);
              let sendingColor;
              if (sendingUser && sendingUser.length > 0) {
                sendingColor = sendingUser.at(0)!.color;
              }
              const classNames = classnames("p-2 rounded-full bg-slate-300", {
                "bg-red-300": sendingColor === Color.RED,
                "bg-orange-300": sendingColor === Color.ORANGE,
                "bg-lime-300": sendingColor === Color.LIME,
                "bg-teal-300": sendingColor === Color.TEAL,
                "bg-purple-300": sendingColor === Color.PURPLE,
              });
              return (
                <div
                  key={`message-${idx}`}
                  id={`message-${idx}`}
                  className={classnames("flex flex-row py-2", {
                    "justify-start": message.sender.userId === user.userId,
                    "justify-end": message.sender.userId !== user.userId,
                  })}
                >
                  <p className={classNames}>{message.message}</p>
                </div>
              );
            })}
        </div>
        <div id="chatInput" className="flex flex-row py-2">
          <input
            className="flex items-center h-10 w-full rounded px-3 text-sm"
            type="text"
            placeholder="Type your message…"
            value={newMessage}
            onChange={(event) => setNewMessage(event.target.value)}
          />
          <ArrowCircleRightIcon
            className="pl-2 h-8 cursor-pointer dark:stroke-white self-center"
            onClick={() => clickSendMessage()}
          />
        </div>
      </div>
    </BaseModal>
  );
};
