import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  value: string;
  width?: number;
  height?: number;
  onClick: (value: string) => void;
  isRevealing?: boolean;
};

export const Key = ({ children, width = 50, height = 55, value, onClick }: Props) => {
  const styles = {
    width: `${width}px`,
    height: `${height}px`,
  };

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick(value);
    event.currentTarget.blur();
  };

  return (
    <button
      style={styles}
      aria-label={`${value}`}
      className={
        "flex items-center justify-center rounded mx-0.5 text-xs font-bold cursor-pointer select-none bg-slate-300 dark:bg-slate-800 dark:text-white"
      }
      onClick={handleClick}
    >
      {children || value}
    </button>
  );
};
