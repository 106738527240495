import React from "react";
import { LoadingCell } from "./LoadingCell";

export const SkeletonGrid = () => {
  const rows = 8;
  const columns = 8;

  return (
    <div className="flex flex-col mx-8">
      {Array(rows)
        .fill(undefined)
        .map((row: any, i: number) => (
          <div key={`row_${i}`} className="flex grow flex-row justify-center">
            {Array(columns)
              .fill(undefined)
              .map((square: any, j: number) => (
                <LoadingCell key={`${j}.${i}`} numRows={rows} rowSize={columns} />
              ))}
          </div>
        ))}
    </div>
  );
};
