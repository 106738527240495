type Props = {
  timeElapsed: number;
};

export const Timer = ({ timeElapsed }: Props) => {
  const h = Math.floor(timeElapsed / 3600);
  const m = Math.floor(timeElapsed % 3600 / 60);
  const s = Math.floor(timeElapsed % 3600 % 60);
  const fmtString = `${('0' + h).slice(-2)}:${('0' + m).slice(-2)}:${('0' + s).slice(-2)}`;

  return (
    <p className="text-xl font-bold dark:text-white">{fmtString}</p>
  );
};
