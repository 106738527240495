import {
  ChartBarIcon,
  ChatAlt2Icon,
  CogIcon,
  InformationCircleIcon,
  SearchCircleIcon,
  UserAddIcon,
} from "@heroicons/react/outline";
import { LightBulbIcon, UserGroupIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import { GAME_TITLE } from "../../constants/strings";
import { Room } from "../../types/room";
import { Timer } from "./Timer";

type Props = {
  roomStatus: Room | undefined;
  isChatModalOpen: boolean;
  isTimerDisplayed: boolean;
  setIsChatModalOpen: (value: boolean) => void;
  setIsFeedbackModalOpen: (value: boolean) => void;
  setIsHelpModalOpen: (value: boolean) => void;
  setIsInfoModalOpen: (value: boolean) => void;
  setIsInviteModalOpen: (value: boolean) => void;
  setIsRoomModalOpen: (value: boolean) => void;
  setIsStatsModalOpen: (value: boolean) => void;
  setIsSettingsModalOpen: (value: boolean) => void;
  setUnseenChatNotification: (value: boolean) => void;
  timeElapsed: number;
  unseenChatNotification: boolean;
};

export const Navbar = ({
  roomStatus,
  isChatModalOpen,
  isTimerDisplayed,
  setIsChatModalOpen,
  setIsFeedbackModalOpen,
  setIsHelpModalOpen,
  setIsInfoModalOpen,
  setIsInviteModalOpen,
  setIsRoomModalOpen,
  setIsStatsModalOpen,
  setIsSettingsModalOpen,
  setUnseenChatNotification,
  timeElapsed,
  unseenChatNotification,
}: Props) => {
  return (
    <div className="navbar mx-0">
      <div className="navbar-content px-2 md:px-5">
        <div className="flex flex-row">
          <button className="h-6 w-6 mr-1.5" onClick={() => setIsInfoModalOpen(true)} aria-label="Open Info">
            <InformationCircleIcon className="cursor-pointer dark:stroke-white" />
          </button>
          <button className="h-6 w-6" onClick={() => setIsFeedbackModalOpen(true)} aria-label="Open Feedback">
            <LightBulbIcon className="cursor-pointer dark:stroke-white" />
          </button>
        </div>
        <div id="navbarTitle">
          { isTimerDisplayed ? <Timer timeElapsed={timeElapsed}/> : <p className="text-xl font-bold dark:text-white">{GAME_TITLE}</p>}
        </div>
        <div className="flex flex-row">
          {!roomStatus || (roomStatus.players && roomStatus.players.length < 2) ? (
            <button className="h-6 w-6 mr-1.5 md:mr-3" onClick={() => setIsInviteModalOpen(true)} aria-label="Add User">
              <UserAddIcon className="cursor-pointer dark:stroke-white" />
            </button>
          ) : (
            <button
              className="h-6 w-6 mr-1.5 md:mr-3"
              onClick={() => setIsRoomModalOpen(true)}
              aria-label="Open Room Info"
            >
              <UserGroupIcon className=" dark:stroke-white" />
            </button>
          )}
          {!roomStatus || (roomStatus.players && roomStatus.players.length < 2) ? (
            <div />
          ) : (
            <button
              className="h-6 w-6 mr-1.5 md:mr-3"
              onClick={() => {
                setIsChatModalOpen(!isChatModalOpen);
                setUnseenChatNotification(false);
              }}
              aria-label="Open Chat"
            >
              <ChatAlt2Icon
                className={classnames({
                  "cursor-pointer dark:stroke-white": true,
                  "rounded-full transition ease-in-out duration-300 p-1 bg-slate-700 stroke-white dark:bg-slate-200 dark:stroke-black":
                    isChatModalOpen,
                  "stroke-red-500 dark:stroke-red-500": unseenChatNotification,
                })}
              />
            </button>
          )}
          <button
            className="h-6 w-6 mr-1.5 md:mr-3"
            onClick={() => setIsHelpModalOpen(true)}
            aria-label="Open Check/Reveal"
          >
            <SearchCircleIcon className="cursor-pointer dark:stroke-white" />
          </button>
          <button className="h-6 w-6 mr-1.5 md:mr-3" onClick={() => setIsStatsModalOpen(true)} aria-label="Open Stats">
            <ChartBarIcon className="cursor-pointer dark:stroke-white" />
          </button>
          <button className="h-6 w-6" onClick={() => setIsSettingsModalOpen(true)} aria-label="Open Settings">
            <CogIcon className="cursor-pointer dark:stroke-white" />
          </button>
        </div>
      </div>
      <hr></hr>
    </div>
  );
};
