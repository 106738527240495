import { DateTime } from "luxon";
import { v4, validate } from "uuid";
import { Player, Room } from "../types/room";
import { loadRoomFromLocalStorage, saveRoomToLocalStorage } from "./LocalStorage";

export class RoomManagement {
  static createRoom(user: Player): Room {
    return {
      roomId: v4(),
      createdTime: DateTime.now().toSeconds(),
      players: [user],
    };
  }
  static getRoomFromUrlOrStorage(user: Player): Room | undefined {
    // Get the Room from the URL
    let room: Room | undefined;
    const currentUrlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const paramRoomId = currentUrlParams.get("roomId");
    // Get the Room from Storage
    const storedRoom = loadRoomFromLocalStorage();
    // They should be the same, use the Room from Storage
    if (storedRoom && storedRoom.roomId === paramRoomId) {
      room = storedRoom;
    }
    // If the Rooms are different, return the one from the URL
    else {
      if (paramRoomId && validate(paramRoomId)) {
        room = {
          roomId: paramRoomId,
          createdTime: DateTime.now().toSeconds(),
          players: [user],
        };
      }
      // If it fails to validate, return the stored room (or undefined)
      else {
        room = storedRoom;
      }
    }
    // If we found a room, set the URL
    if (room) {
      return room;
    } else {
      return undefined;
    }
  }
  static saveRoomAndCopyInviteUrlToClipboard(room: Room): URL {
    const url: URL = new URL(window.location.href);
    saveRoomToLocalStorage(room);
    url.searchParams.set("roomId", room.roomId);
    window.history.pushState({}, "", url);
    return url;
  }
}
