import { useState } from "react";
import { Color, Player } from "../../../types/room";

type Props = {
  user: Player;
  handleUserUpdate: (userName: string, color: Color | undefined) => void;
};

export const UserNameInput = ({ user, handleUserUpdate }: Props) => {
  const [userName, setUserName] = useState(user.userName);

  return (
    <div className="mt-2 text-left pt-2">
      <p className="text-gray-500 dark:text-white">User Name</p>
      <div className="flex my-2 justify-around">
        <input
          type="text"
          id="name"
          name="name"
          className="form-input rounded-full w-2/3"
          placeholder={user.userName}
          minLength={4}
          maxLength={22}
          size={16}
          onChange={(event) => setUserName(event.target.value)}
        />
        <button className="bg-gray-300 p-1 px-3 rounded-full" onClick={() => handleUserUpdate(userName, user.color)}>
          Update
        </button>
      </div>
    </div>
  );
};
