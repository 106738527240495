import classnames from "classnames";
import { Direction } from "../../types/play";

type Props = {
  clueNumber: number;
  clueText: string;
  direction: Direction;
  solution: string;
  onSelectClue: (clueNumber: number, direction: Direction) => void;
  isActive?: boolean;
  isChecked?: boolean;
  isRevealed?: boolean;
  isHighlighted?: boolean;
  lastUpdateUser?: string;
};

export const ClueItem = ({
  clueNumber,
  clueText,
  direction,
  onSelectClue,
  isActive = false,
  isChecked = false,
  isRevealed = false,
  isHighlighted = false,
  lastUpdateUser,
}: Props) => {
  const classes = classnames({
    "flex w-full py-0.5 text-left text-1xl dark:text-white cursor-pointer select-none": true,
    "active bg-sky-500/50": isActive,
  });

  return (
    <button
      className={classes}
      onClick={() => onSelectClue(clueNumber, direction)}
      aria-label={`Clue ${clueNumber} ${direction}`}
    >
      <div>
        {clueNumber}: {clueText}
      </div>
    </button>
  );
};
