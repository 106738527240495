import { Clue, Direction } from "../../types/play";
import { ClueItem } from "./ClueItem";

type Props = {
  clues: Clue[];
  currentClue: Clue | undefined;
  direction: Direction;
  onSelectClue: (clueNumber: number, direction: Direction) => void;
};

export const ClueSection = ({ clues, currentClue, direction, onSelectClue }: Props) => {
  return (
    <div id="clueSection" className="px-3 basis-1/2">
      <div className="pb-3 text-center text-3xl dark:text-white font-bold">{direction}</div>
      <div className="overflow-y-auto">
        {clues.map((clue: Clue, i: number) => (
          <ClueItem
            key={i}
            clueNumber={clue.clueNumber}
            clueText={clue.clueText}
            direction={direction}
            isActive={
              currentClue ? currentClue.clueNumber === clue.clueNumber && currentClue.direction === direction : false
            }
            onSelectClue={onSelectClue}
            solution={clue.solution}
          />
        ))}
      </div>
    </div>
  );
};
