import React, { useEffect } from "react";

type Props = {
  clueNumber: number;
  clueText: string;
  onClickClueBar: () => void;
  onDoubleClickClueBar: () => void;
  onSelectNextClue: (moveLeft: boolean) => void;
};

export const ClueBar = ({ 
  clueNumber,
  clueText,
  onClickClueBar,
  onDoubleClickClueBar,
  onSelectNextClue
 }: Props) => {
  const buttonClassNames =
    "basis-1/12 w-full h-full w-7 flex justify-center items-center cursor-pointer select-none md:hidden bg-slate-300 dark:text-white dark:bg-slate-800";

  const [clicks, setClicks] = React.useState<number>(0);
  
  useEffect(() => {
    let singleClickTimer: any;
    if (clicks === 1) {
      singleClickTimer = setTimeout(function() {
        onClickClueBar()
        setClicks(0);
      }, 1);
    } else if (clicks === 2) {
        onDoubleClickClueBar()
        setClicks(0);
    }
    return () => clearTimeout(singleClickTimer);
  }, [clicks, onClickClueBar, onDoubleClickClueBar]);

  return (
    <div className="flex flex-row justify-center px-1 max-w-md grow md:grow-0 md:basis-3/4">
      <button id="leftArrow" className={buttonClassNames} onClick={() => onSelectNextClue(true)}>
        {"<"}
      </button>
      <button
        id="clueBar"
        className="basis-5/6 h-14 px-2 flex justify-start items-center cursor-pointer select-none text-sm dark:text-white md:text-2xl"
        onClick={() => setClicks(clicks + 1)}
      >
        {clueNumber}: {clueText}
      </button>
      <button id="rightArrow" className={buttonClassNames} onClick={() => onSelectNextClue(false)}>
        {">"}
      </button>
    </div>
  );
};
