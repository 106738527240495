import { UAParser } from "ua-parser-js";
import { GAME_TITLE } from "../constants/strings";
import { DailyStats } from "../types/stats";

const webShareApiDeviceTypes: string[] = ["mobile", "smarttv", "wearable"];
const parser = new UAParser();
const browser = parser.getBrowser();
const device = parser.getDevice();

export const shareStatus = (
  date: string,
  dayOfWeek: string,
  handleShareToClipboard: () => void,
  isGameWon: boolean,
  usedCheckOrReveal: boolean,
  weekdayStats: DailyStats
) => {
  // Game Info: ex. "Crossnoodle 🐛 2023-03-18 🔎"
  const gameInfo = `${GAME_TITLE} ${date} ${usedCheckOrReveal ? "🔎" : "🏆"}`;
  // Weekday + Streak Info: ex. "Saturday 0/1 - Streak: 0"
  const weekdayInfo = `${dayOfWeek} ${weekdayStats.gamesWon}/${weekdayStats.gamesPlayed}`;
  const streakInfo = `Streak: ${weekdayStats.currentStreak ? weekdayStats.currentStreak : 0}`;

  const textToShare = `${gameInfo}\n${weekdayInfo} - ${streakInfo}`;
  const shareData = { text: textToShare };
  let shareSuccess = false;

  try {
    if (attemptShare(shareData)) {
      navigator.share(shareData);
      shareSuccess = true;
    }
  } catch (error) {
    shareSuccess = false;
  }

  if (!shareSuccess) {
    navigator.clipboard.writeText(textToShare);
    handleShareToClipboard();
  }
};

const attemptShare = (shareData: object) => {
  return (
    // Deliberately exclude Firefox Mobile, because its Web Share API isn't working correctly
    browser.name?.toUpperCase().indexOf("FIREFOX") === -1 &&
    webShareApiDeviceTypes.indexOf(device.type ?? "") !== -1 &&
    navigator.canShare &&
    navigator.canShare(shareData) &&
    navigator.share
  );
};
