import {
  ChatMessageEvent,
  GameUpdateEvent,
  Message,
  MessageType,
  PuzzleUpdateEvent,
  RoomJoinEvent,
  RoomUpdateEvent,
  SquareUpdateEvent,
  UpdateAction,
} from "../types/messages";
import { CrosswordPuzzle, Square } from "../types/play";
import { ChatMessage, Player, Room } from "../types/room";
import { CrosswordUtils } from "./CrosswordUtils";

export class WebsocketHandler {
  static parseMessage(message: MessageEvent<any>): Message | undefined {
    try {
      return JSON.parse(message.data) as Message;
    } catch (ex: any) {
      console.error("Unable to read message from server: " + ex.toString());
    }
  }
  static sendChatMessageEvent(user: Player, room: Room, chatMessage: ChatMessage): string {
    return JSON.stringify({
      messageType: MessageType.ChatMessage,
      sender: user,
      room: room,
      chatMessage: chatMessage,
    } as ChatMessageEvent);
  }
  static sendGameUpdateEvent(user: Player, room: Room, puzzle: CrosswordPuzzle): string {
    return JSON.stringify({
      messageType: MessageType.GameUpdate,
      sender: user,
      room: room,
      puzzle: puzzle,
    } as GameUpdateEvent);
  }
  static sendPuzzleUpdateEvent(user: Player, room: Room, puzzle: CrosswordPuzzle, alert?: string): string {
    return JSON.stringify({
      alert: alert,
      messageType: MessageType.PuzzleUpdate,
      sender: user,
      room: room,
      puzzle: puzzle,
    } as PuzzleUpdateEvent);
  }
  static sendRoomJoinEvent(user: Player, room: Room): string {
    return JSON.stringify({
      messageType: MessageType.JoinRoomRequest,
      sender: user,
      room: room,
    } as RoomJoinEvent);
  }
  static sendRoomUpdateEvent(user: Player, room: Room, alert?: string): string {
    return JSON.stringify({
      alert: alert,
      messageType: MessageType.RoomUpdate,
      sender: user,
      room: room,
    } as RoomUpdateEvent);
  }
  static sendSquareUpdateEvent(user: Player, room: Room, square: Square): string {
    return JSON.stringify({
      messageType: MessageType.SquareUpdate,
      sender: user,
      room: room,
      square: square,
    } as SquareUpdateEvent);
  }
  static receiveMessage(
    user: Player,
    room: Room,
    currentPuzzle: CrosswordPuzzle,
    message: MessageEvent<any>
  ): UpdateAction {
    const messageData = this.parseMessage(message);
    console.log(messageData);
    if (!messageData) {
      return {} as UpdateAction;
    }
    let update = {} as UpdateAction;
    switch (messageData.messageType) {
      case MessageType.ChatMessage:
        const newMessage = messageData as ChatMessageEvent;
        update = {
          chatMessage: newMessage.chatMessage,
        };
        break;
      case MessageType.GameUpdate: {
        const gameUpdate = messageData as GameUpdateEvent;
        update = {
          puzzle: gameUpdate.puzzle,
          room: gameUpdate.room,
        };
        break;
      }
      case MessageType.PlayerJoinedRoom: {
        const newRoom = {
          ...room,
          players: messageData.room.players,
        };
        update = {
          alert: `${messageData.sender.userId === user.userId ? "You" : messageData.sender.userName} joined the room!`,
          room: newRoom,
        };
        if (messageData.sender.userId !== user.userId) {
          update.sendMessage = this.sendGameUpdateEvent(
            messageData.room.players.find((user) => user.userId === messageData.sender.userId)!,
            newRoom,
            currentPuzzle
          );
        }
        break;
      }
      case MessageType.PlayerLeftRoom:
        const newRoom = {
          ...room,
          players: room.players.map((player) => {
            if (player.userId === messageData.sender.userId) {
              player.online = false;
            }
            return player;
          }),
        };
        const newPuzzle = CrosswordUtils.setSquares(
          currentPuzzle,
          currentPuzzle.grid.flatMap((row) =>
            row
              .filter((square) => square.activeUser?.userId === messageData.sender.userId)
              .map((square) => ({ ...square, activeUser: undefined }))
          )
        );
        update = {
          alert: `${messageData.sender.userId === user.userId ? "You" : messageData.sender.userName} left the room!`,
          room: newRoom,
          puzzle: newPuzzle,
        };
        break;
      case MessageType.PuzzleUpdate: {
        const puzzleUpdate = messageData as PuzzleUpdateEvent;
        update = {
          puzzle: puzzleUpdate.puzzle,
        };
        if (messageData.alert) {
          update.alert = messageData.alert;
        }
        break;
      }
      case MessageType.RoomUpdate: {
        const roomUpdate = messageData as RoomUpdateEvent;
        update = {
          room: roomUpdate.room as Room,
        };
        if (messageData.alert) {
          update.alert = messageData.alert;
        }
        break;
      }
      case MessageType.SquareUpdate: {
        const squareUpdate = messageData as SquareUpdateEvent;
        update = {
          puzzle: CrosswordUtils.setSquares(currentPuzzle, [squareUpdate.square]),
        };
        break;
      }
    }

    return update;
  }
}
