import { INCOMPLETE_PUZZLE_MESSAGE } from "../../constants/strings";
import { BaseModal } from "./BaseModal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const GameIncompleteModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Oooh, almost!" isOpen={isOpen} handleClose={handleClose}>
      <div>
        <p className="mb-3 text-sm text-gray-500 dark:text-gray-300">{INCOMPLETE_PUZZLE_MESSAGE}</p>
      </div>

      <div
        className={"p-1 px-3 bg-sky-300 rounded-full duration-300 cursor-pointer bg-sky-500 text-white"}
        onClick={handleClose}
      >
        <p>OK</p>
      </div>
    </BaseModal>
  );
};
