import { BadgeCheckIcon, BanIcon } from "@heroicons/react/solid";
import classnames from "classnames";
import { useMemo } from "react";
import { Color, Player, Room } from "../../../types/room";
import { BaseModal } from "../BaseModal";
import { UserColorPicker } from "./UserColorPicker";
import { UserNameInput } from "./UserNameInput";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleInvite: () => URL;
  roomStatus: Room | undefined;
  user: Player;
  handleUserUpdate: (userName: string, color: Color | undefined) => void;
};

export const RoomModal = ({ isOpen, handleClose, handleInvite, handleUserUpdate, roomStatus, user }: Props) => {
  const players = useMemo(() => {
    return roomStatus ? roomStatus.players! : ([] as Player[]);
  }, [roomStatus]);

  return (
    <BaseModal title="Your Crossnoodle Room" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex justify-between pt-3">
        <div className="text-gray-500 dark:text-white mt-2 text-left">
          <p className="leading-none">Connected</p>
        </div>
        {roomStatus ? (
          <BadgeCheckIcon className="h-6 w-6 mr-1.5 md:mr-3 dark:stroke-white" />
        ) : (
          <BanIcon className="h-6 w-6 mr-1.5 md:mr-3 dark:stroke-white" />
        )}
      </div>
      <div className="mt-2 text-left pt-2">
        <p className="text-gray-500 dark:text-white">Players</p>
        {players ? (
          <div>
            {players
              .sort((x, y) => x.userId.localeCompare(y.userId))
              .map((player: Player) => {
                const classNames = classnames("font-bold", {
                  "text-red-600": player.color === Color.RED,
                  "text-orange-600": player.color === Color.ORANGE,
                  "text-lime-600": player.color === Color.LIME,
                  "text-teal-600": player.color === Color.TEAL,
                  "text-purple-600": player.color === Color.PURPLE,
                  "opacity-50": player.online === false,
                });
                return (
                  <div key={JSON.stringify(player)} className="text-center my-2">
                    <div className={classNames}>
                      <p className="leading-none">
                        {player.userId === user.userId ? `${user.userName} (you)` : player.userName}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div />
        )}
      </div>
      <UserNameInput user={user} handleUserUpdate={handleUserUpdate} />
      <UserColorPicker user={user} handleUserUpdate={handleUserUpdate} />
      <div
        className={"p-1 mt-3 bg-sky-300 rounded-full duration-300 cursor-pointer bg-sky-500 text-white"}
        onClick={() => {
          const roomUrl = handleInvite();
          navigator.clipboard.writeText(roomUrl.toString());
        }}
      >
        <p>Get Invite Link</p>
      </div>
    </BaseModal>
  );
};
