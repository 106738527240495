export const GAME_TITLE = "Crossnoodle 🐛";
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "You are using an embedded browser and may experience problems sharing or saving your results. We encourage you rather to use your device's default browser.";
export const INCOMPLETE_PUZZLE_MESSAGE = "Uh oh, looks like there's a mistake somewhere.";
export const WIN_MESSAGES = ["Great Job!", "Awesome", "Well done!"];
export const COMPLETED_MESSAGES = ["All done.", "Whew.", "Glad that's over."];
export const DELETE_TEXT = "Delete";
export const ENTER_TEXT = "Enter";
export const GAME_COPIED_MESSAGE = "Game copied to clipboard";
export const EXAMPLE_NAMES = ["Harry", "Dudley", "Hermione", "Ron", "Ginny", "Snape", "Dumbledore"];
export const NEW_PUZZLE_TEXT = "New puzzle in";
export const TOTAL_GAMES_TEXT = "Total games";
export const GAMES_WON = "Games won";
export const PUZZLE_SUCCESS_DISTRIBUTION_TEXT = "Wins by day";
export const COOP_STATISTICS_TITLE = "Top Coop Statistics";
export const SOLO_STATISTICS_TITLE = "Solo Statistics";
export const RESET_STATS_BUTTON_TEXT = "Reset Stats";
export const STATS_RESET_MESSAGE = "Stats were reset!";
export const FEEDBACK_POSTED_MESSAGE = "Feedback sent!";
export const FEEDBACK_FAILED_MESSAGE = "Feedback failed to send!";
export const CLUE_FLAG_SUCCESS_MESSAGE = "Clue flagged! Thanks for improving Crossnoodle 🐛";
export const CLUE_FLAG_FAILED_MESSAGE = "Failed to flag clue! Sorry about that 😿";
export const USER_NAME_TEXT = "Username";
export const SHARE_TEXT = "Share";
export const SUCCESS_RATE_TEXT = "Success rate";
export const CURRENT_STREAK_TEXT = "Current streak";
export const BEST_STREAK_TEXT = "Best streak";
