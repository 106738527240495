import { DateTime } from "luxon";
import { Stats } from "../../types/stats";
import { Progress } from "./Progress";

type Props = {
  stats: Stats;
  isGameWon: boolean;
};

const isCurrentDayStatRow = (i: number): boolean => {
  return i === DateTime.now().weekday - 1;
};

export const Histogram = ({ stats, isGameWon }: Props) => {
  const winDistribution = stats.winDistribution;
  const maxValue = Math.max(...winDistribution.map((x) => x.gamesWon), 5);

  return (
    <div className="columns-1 justify-left m-2 text-sm dark:text-white">
      {winDistribution.map((dailyStats, i) => (
        <Progress
          key={i}
          index={i}
          isCurrentDayStatRow={isCurrentDayStatRow(i)}
          isGameWon={isGameWon}
          gamesTotal={dailyStats.gamesPlayed}
          gamesWon={dailyStats.gamesWon}
          lossSize={55 * ((dailyStats.gamesPlayed - dailyStats.gamesWon) / maxValue)}
          winSize={55 * (dailyStats.gamesWon / maxValue)}
        />
      ))}
    </div>
  );
};
