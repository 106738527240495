import { BaseModal } from "../BaseModal";
import { SettingsToggle } from "./SettingsToggle";

type Props = {
  currentPuzzleDate: string;
  handleClose: () => void;
  handleDarkMode: Function;
  handleHighContrastMode: Function;
  handleSelectGame: (date: string) => void;
  handleSwitchEnterDelete: Function;
  handleUpdateIsTimerDisplayed: Function;
  isDarkMode: boolean;
  isHighContrast: boolean;
  isOpen: boolean;
  isSwitchEnterDelete: boolean;
  isTimerDisplayed: boolean;
};

export const SettingsModal = ({
  currentPuzzleDate,
  handleClose,
  handleDarkMode,
  handleHighContrastMode,
  handleSelectGame,
  handleSwitchEnterDelete,
  handleUpdateIsTimerDisplayed,
  isDarkMode,
  isHighContrast,
  isOpen,
  isSwitchEnterDelete,
  isTimerDisplayed,
}: Props) => {
  return (
    <BaseModal title="Settings" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col mt-2 divide-y">
        {/* <NewGamePicker currentPuzzleDate={currentPuzzleDate} onSelect={handleSelectGame} /> */}
        <SettingsToggle settingName="Dark Mode" flag={isDarkMode} handleFlag={handleDarkMode} />
        {/* <SettingsToggle settingName="High Contrast" flag={isHighContrast} handleFlag={handleHighContrastMode} /> */}
        <SettingsToggle
          settingName="Switch Enter/Delete Keys"
          flag={isSwitchEnterDelete}
          handleFlag={handleSwitchEnterDelete}
        />
        <SettingsToggle settingName="Enable Timer" flag={isTimerDisplayed} handleFlag={handleUpdateIsTimerDisplayed} />
      </div>
    </BaseModal>
  );
};
